@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.3.0/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.3.0/css/lg-zoom.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.3.0/css/lg-video.css');

:root {
    --primary-white: #fff;
    --primary-light-grey: #f4f4f4;
    --primary-grey: #ccd1d9;
    --primary-dark-grey: #333;
    --primary-red: #D80F2D;
    --primary-red-hover: #F3435D;
    --primary-black: #000;
    --primary-grey-hover: #616161;
    --primary-dark: rgb(52, 58, 64);
    --primary-transparent-black: rgb(0, 0, 0, 0.7);
    --primary-green: #52C264;
    --primary-background-grey: #FAFAFA;
    --primary-dark-blue: #232E57;
    --clr-grey: #B4B4B4;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
    color: var(--primary-dark-grey);
    outline: none;
    list-style: none;
}

button {
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.logo {
    width: 125px;
    padding-top: 1%;
}

/* whatsapp */
.whatsapp {
    position: fixed;
    bottom: 40px;
    right: 20px;
    width: 50px;
    height: 50px;
    /* background: var(--primary-green); */
    background-color: #0FD776;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    z-index: 100;
}

.whatsapp>i {
    color: var(--primary-white);
    position: absolute;
    top: 20%;
    right: 22%;
    transition: .3s transform;
}

.whatsapp>i:hover {
    transform: scale(1.1);
}


/* Navbar */
.navbar {
    width: 100%;
    height: 4.5rem;
    background-color: var(--primary-white);
    border-bottom: 1px solid #E7E7E7;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 9999;
    overflow: hidden;
}

.nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5% 2% 0;

}

/* yenilik */

/* .nav_main {
    display: flex;
    margin-left: auto;
    gap: 200px;
} */


.mobile-menu-icon {
    display: none;
}

.main-menu_list {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.main-menu_item {
    color: var(--primary-dark-grey);
    font-weight: 500;
    letter-spacing: .3px;
    transition: color .3s;
    padding: 1rem;
    font-size: 16px;
}

.main-menu_item:hover {
    color: var(--primary-red);
}

.main-menu_item:focus,
.main-menu_item:active {
    color: var(--primary-red);
}

.search-none {
    width: 0;
    border: none;
}

.fa-xmark {
    /* pointer-events: none; */
    z-index: 9999;
}

.nav-link-right_mob {
    display: none;
}

.nav-link-left {
    display: flex;
    align-items: center;

}

.nav-link-left ul {
    display: flex;
    list-style: none;
}

.line {
    display: inline-block;
}

.line::after {
    content: " ";
    display: block;
    width: 0;
    height: 2px;
    background: var(--primary-red);
    transition: width .3s;
}

.line:hover::after {
    width: 100%;
}

/* nav_link */

.nav-link-right {
    display: flex;
    align-items: center;
}

.nav-link-right .login-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: auto;
    padding: 0 10px;
    border: 1.5px solid var(--primary-red);
    background: transparent;
    color: var(--primary-red);
    font-weight: 600;
    border-radius: 7px;
    margin: 0 1rem;
    font-size: 16px;
    cursor: pointer;
    transition: all .4s;
}

.nav-link-right .login-btn:hover {
    background-color: var(--primary-red-hover);
    color: var(--primary-white);
}

.nav-lang {
    border: none;
    outline: none;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    color: var(--primary-red);
    cursor: pointer;
}

.lang_option {
    font-size: 16px;
    color: var(--primary-red);
    padding-right: 7px;
    margin-left: 7px;
    cursor: pointer;
}

.nav-lang img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.nav-lang i {
    color: var(--primary-red);
}


/* search */

.navbar-search {
    display: flex;
}

.nav_search__toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    position: relative;
    gap: 90px;
}

.form-search_inner>.fa-xmark {
    display: none;
    font-size: 24px;
    margin-left: 7%;
    cursor: pointer;
    transition: .20s ease-in-out;
}

.form-search_inner>.fa-xmark:hover {
    color: var(--primary-red);
}

.nav_search__toggle>i {
    transition: .20s ease-in-out;
}

.nav_search__toggle>i:hover {
    color: var(--primary-red);
}

.search-submit>i {
    font-size: 20px;
    color: var(--primary-red);
}



.search_form {
    position: absolute;
    top: 11%;
    height: 60px;
    left: 0;
    padding-left: 15%;
    right: 33%;
    z-index: 1;
    background-color: #fff;
    opacity: 0;
    pointer-events: none;
    transform: translate(-100%, -50%);
    transform-origin: left top;
    transition: .3s ease-in-out;
}



.js-active {
    pointer-events: all;
    opacity: 1;
    transform: translate(0, 0);
}


.form-search_inner .search-input {
    display: block;
    border: 1px solid #b6b3ba;
    border-radius: 10px;
    width: 80%;
    height: 48px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #fff;
    color: #110f14;
    font-size: 18px;
    box-shadow: 0 0 0 1px transparent;
    transition: .15s ease-in-out;
    margin-right: 30px;
}

.form-search_inner {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}

/* Languages */
.lang_btns {
    display: flex;
    padding: 0;
}

.lang_btns>button>svg {
    width: 2rem;
    padding: 0;
}

.lang_btns>button {
    padding: 0;
}

/* Header css */
.header {
    width: 100%;
    height: 100vh;
    background: linear-gradient(180deg, #F2F2F2 0%, #FFFFFF 52.08%, #EBEBEB 100%);
    display: flex;
    overflow: hidden;
}

.head-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    padding: 10% 0 10% 6%;
}

.head-text>h1 {
    font-size: 48px;
    line-height: 75px;
    color: var(--primary-dark-grey);
}

.head-text>p {
    font-size: 22px;
    padding-top: 2%;
    color: var(--primary-grey-hover);
}

.head-text>h1>span {
    color: var(--primary-red);

    letter-spacing: 1px;
}

.class_text {
    padding: 2% 0;
}

.head-photo {
    display: flex;
    justify-content: flex-end;
    width: 50%;
}

.head-photo img {
    padding-top: 15%;
    width: 60%;
    height: auto;
}

/* partners */
.partners-center {
    padding: 1% 6% 0;
}

.partner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


/* advantages */

.advantages {
    width: 100%;
    overflow-y: hidden;
    padding: 4% 6% 0;
}

.advantages>h2 {
    color: var(--primary-dark-grey);
    width: 65%;
    font-size: 45px;
    letter-spacing: .5px;
}

.advantages>h2>span {
    color: var(--primary-red);
}

.benefits {
    display: flex;
    justify-content: space-between;
    padding: 3% 0;
}

.benefit {
    width: 309px;
    padding-bottom: 5%;
}

.benefit:hover {
    border: 1px solid var(#111);
}

.benefit__title {
    display: flex;
    align-items: center;
}

.benefit__title>i {
    font-size: 28px;
    padding: 0 0 4% 0;
}

.benefit__title>h4 {
    font-size: 20px;
    letter-spacing: .5px;
    padding: 0 0 3% 2%;
    color: var(--primary-dark-grey);
}

.benefit>a {
    color: var(--primary-red);
    font-weight: 600;
    text-decoration: underline;
    padding-left: 6.5%;
    transition: .5s color;
}

.benefit>a:hover {
    color: var(--primary-red-hover);
}

.adv-text {
    display: flex;
    align-items: baseline;
    padding: 2% 0;
}

.adv-text>i {
    color: var(--primary-green);
    padding-right: 2%;
}


/* Gyms */


.gyms {
    width: 100%;
    height: 100%;
    padding: 0 6%;
    background-color: var(--primary-background-grey);
}

.gym-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.gym-heading>h2 {
    font-size: 34px;
    padding: 3% 0;
    letter-spacing: .5px;
}

.gym-heading>.link_btn {
    padding: .8% 2%;
    cursor: pointer;
    border: 1px solid var(--primary-red);
    border-radius: 7px;
    background: var(--primary-white);
    font-weight: 600;
    font-size: 1rem;
    transition: .8s all;
    color: var(--primary-red);
}

.gym-heading>.link_btn:hover {
    background: var(--primary-red);
    color: var(--primary-white);
}

.gym-container {
    border: 1px solid var(--primary-light-grey);
    /* height: 380px; */
    width: 380px;
    margin-bottom: 1rem;
    background: var(--primary-white);
    border-radius: 10px;
    transition: .5s all;
    cursor: pointer;
}

.gym-container:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.gym-container .gym-img {

    /* width: 380px; */
    height: 260px;
    border-radius: 10px;
}

.gym-img-slide {
    width: 380px;
}

.gym-container .gym-img img {
    width: 100%;
    height: 100%;
    background-size: cover;
    border-radius: 10px 10px 0 0;
}

.gym-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.list__view {
    display: flex;
    justify-content: space-between;
}

.list_gym-container {
    border: 1px solid var(--primary-light-grey);
    height: 250px;
    margin-bottom: 1rem;
    background: var(--primary-white);
    border-radius: 10px;
    transition: .5s all;
    cursor: pointer;
}

.compact {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.cheatsheet {
    display: flex;
    flex-direction: column;
}

.gym-container .gym-title {
    font-size: 18px;
    font-weight: 600;
}

.gym-body {
    padding: 0 4%;
}

.gym-wrapper .icon {
    color: #D80F2D;
}

.gym-title {
    padding: 2% 0;
}

.location {
    color: var(--primary-grey-hover);
}

.rating {
    display: flex;
    align-items: center;
    padding: 2% 0;
}

.feedback {
    padding-left: 2%;
    color: var(--primary-grey-hover);
}

/* why us */

.why-us {
    width: 100%;
    padding: 0 6%;
    overflow-y: hidden;
}

.why-us>h2 {
    font-size: 45px;
    padding: 3% 0 0;
    letter-spacing: .5px;
}

.why-us_body {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.why-us_text {
    width: 50%;
}

.why-us_text>h4 {
    color: var(--primary-red);
    font-size: 20px;
    letter-spacing: .5px;
}

.why-us_description>p {
    padding: 3% 0;
}

.why-us_description {
    width: 80%;
}

.why-us_btn {
    padding: 3% 0;
}

.why-us_btn a {
    font-size: 20px;
    text-decoration: underline;
    color: var(--primary-red);
    font-weight: 600;
    transition: .8s all;
}

.why-us_btn i {
    color: var(--primary-red);
    margin-left: 1%;
}

.why-us_img {
    width: 50%;
}

.why-us_img>img {
    width: 90%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
}

.why-us_img {
    position: relative;
}

.why-us_img .rectangle {
    position: absolute;
    left: 20%;
    top: -.7%;
    width: 50%;
    height: 100%;
    background: linear-gradient(90deg, rgba(26, 41, 128, 0.45) 0%, rgba(38, 208, 206, 0.45) 100%);
}

/* testimonials */

.testimonials {
    width: 100%;
    background: var(--primary-dark-blue);
    padding: 0 6% 4%;
}

.testimonials-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5% 0;
}

.testimonials-header>h2 {
    color: var(--primary-white);
    font-size: 45px;
}

.card {
    width: 400px;
    height: 320px;
    position: relative;
    background-color: var(--primary-white);
    border-radius: 10px;
    margin-right: 30px;
}

.card>.quotation-mark {
    position: absolute;
    left: 5%;
    top: 7%;
}

.card>.details {
    line-height: 27px;
    width: 95%;
    padding: 18% 2% 5% 10%;

}

.card>.user-testimonials {
    display: flex;
    align-items: center;
    padding: 5% 0 0 5%;
}

.user-img {
    width: 68px;
    height: 68px;
}

.user-name {
    margin-left: .5rem;
}

.user-name>h4 {
    color: var(--primary-red);
    letter-spacing: 1px;
}




/* package */
.price-table {
    width: 100%;
    background: var(--primary-background-grey);
    padding: 3% 6%;
    overflow: hidden;
}

.price-table h2 {
    font-size: 40px;
    padding: 3% 0;
}

.package {
    width: 360px;
    background-color: var(--primary-white);
    border-radius: 15px;
    border: 1px solid var(--primary-grey);
    cursor: pointer;
    transition: .5s all;
}

.package-title {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    height: 180px;
    background: var(--primary-light-grey);
}

.packages {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.package-title {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.package-title .name {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 1px;
    text-align: center;
    color: var(--primary-dark-blue);
    padding: 3%;
}

.package-title .info {
    color: var(--primary-grey-hover);
}

.price {
    display: flex;
    align-items: center;
    padding: 3%;
}

.price .number {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 1px;
    color: var(--primary-dark-blue);
}

.price>i {
    color: var(--primary-dark-blue);
}

.package-main .details {
    display: flex;
    align-items: baseline;
    padding: .9% 4%;
}

.details>i {
    color: var(--primary-green);
    padding-right: 2%;
}

.details>p {
    font-size: 14px;
}

.package:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

/* promotion */
.promotion {
    width: 100%;
    /* height: 400px; */
    background-color: #111;
    display: flex;
    overflow: hidden;
}

.promotion-main {
    padding: 0 0 0 6%;
}

.promotion-main h2 {
    color: var(--primary-white);
    font-weight: 500;
    font-size: 40px;
    width: 70%;
    padding: 7% 3% 3% 0;
}

.promotion-main p {
    color: var(--primary-white);
    font-size: 20px;
    letter-spacing: .5px;
}

.prm-buttons {
    padding: 5% 0;
}

.prm-buttons .app-store {
    margin-right: 15px;
    width: 150px;
    transition: .5s all;
}

.prm-buttons .play-market {
    width: 150px;
    transition: .5s all;
}

.prm-buttons .app-store:hover {
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(255, 255, 255, 0.32) 0px 2px 16px 0px;
}

.prm-buttons .play-market:hover {
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(255, 255, 255, 0.32) 0px 2px 16px 0px;
}

.promotion-video {
    height: auto;
    background: var(--primary-white);
}

.promotion-video>img {
    width: 100%;
    height: 100%;
}

/* Footer */
.footer {
    width: 100%;
    height: auto;
    padding: 5% 6% 0;
    background-color: var(--primary-black);
}

.footer-body {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 7% 0;
}

.sp-logo>p {
    color: var(--clr-grey);
    padding: 15% 0 0;
}

.footer-title {
    color: var(--primary-white);
    font-weight: 500;
    font-size: 18px;
    padding: 3% 0;
}

.sports .sport,
.company,
.contact {
    display: flex;
    flex-direction: column;
}

.sport>a,
.company>a,
.contact>a {
    color: var(--clr-grey);
    font-weight: 300;
    padding: 3% 0;
    transition: .5s all;
}

.sport>a:hover,
.company>a:hover,
.contact>a:hover,
.footer-content .footer_link:hover {
    color: var(--primary-white);
    text-shadow: 2px 2px 8px #FF0000;
}

.contact>.icons>a>i {
    padding: 2%;
    font-size: 24px;
    cursor: pointer;
    transition: .4s all;
}

.contact>.icons>a>i:hover {
    color: var(--primary-white);
}

.footer-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid var(--primary-grey-hover);
}

.footer-content p {
    text-align: center;
    padding: 2% 0;
    font-size: 16px;
    color: var(--primary-background-grey);
    letter-spacing: .5px;
}

.footer-content .footer_link {
    color: var(--primary-grey);
    transition: .3s all;
}

.footer-content p>span {
    font-size: 16px;
    color: var(--primary-background-grey);
    letter-spacing: .5px;
}

/* Discover */

.discover {
    position: absolute;
    right: 53%;
    bottom: -585%;
    width: 555px;
    height: 170px;
    background: linear-gradient(224.03deg, #D80F2D 18.21%, #232E57 72.91%);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.discover p {
    color: var(--primary-white);
    font-weight: 500;
    font-size: 22px;
    width: 60%;
    letter-spacing: 1px;
}

.discover a {
    padding: 2% 4%;
    background: #D5D5D5;
    border-radius: 10px;
    font-weight: 600;
    margin-left: 2%;
    transition: .5s all;
}

.discover a:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}


/* ABOUT PAGE */
.about-header {
    padding: 0 6%;
    width: 100%;
    height: 100vh;
    background-color: var(--primary-background-grey);
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    overflow-y: hidden;
}

.about-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-text>h1 {
    padding-top: 2%;
    text-align: center;
    letter-spacing: .5px;
    font-size: 45px;
    margin-bottom: 5px;
}

.txt-title {
    color: var(--primary-grey-hover);
}

.about-text .txt-description {
    width: 50%;
    padding-bottom: 5px;
    color: var(--primary-grey-hover);
}

.about-text>a {
    padding: 10px 30px;
    border-radius: 10px;
    background: var(--primary-red);
    color: var(--primary-white);
    font-weight: 500;
    letter-spacing: 1px;
    margin-top: 15px;
    font-size: 18px;
    transition: .3s all;
}

.about-text>a:hover {
    background: var(--primary-red-hover);
}

/* About Image */

.about-img {
    overflow-y: hidden;
    padding: 5% 6%;
}

.img-container {
    width: 100%;
    height: 774px;
    overflow: hidden;
}

.img-container>img {
    width: 100%;
    height: 100%;
    border-radius: 40px;
    background-size: cover;
    overflow: hidden;
}

/* management tools */
.partner_advantages {
    padding: 6% 0;
    overflow-y: hidden;
}

.user_advantages {
    overflow-y: hidden;
}

.partner_advantages h2,
.user_advantages h2 {
    letter-spacing: 1px;
    font-size: 45px;
    text-align: center;
    padding-bottom: 6%;
}

.partner_advantages h2 span,
.user_advantages h2 span {
    color: var(--primary-red);
}

.partner_body,
.user_body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4% 6%;
    overflow: hidden;
}

.partner_main,
.user_main {
    width: 45%;
}

.user_main {
    padding-left: 2%;
}

.partner_main>h4,
.user_main>h4 {
    font-size: 32px;
    padding-bottom: 7%;
}

.partner_img,
.user_img {
    width: 594px;
    height: 480px;
    background: #F3F3F3;
    border-radius: 20px;
    position: relative;
}

.partner_img img,
.user_img img {
    width: 100%;
    background-size: cover;
    position: absolute;
    top: 12%;

}

.partner_description>p,
.user_description>p {
    width: 90%;
    padding-top: 2%;
}

.partner_description>p>span,
.user_description>p>span {
    font-weight: 600;
    color: #686868;
}

.about_section_partners>h2>span {
    color: var(--primary-red);
}

.partner_description .description_header,
.user_description .description_header {
    display: flex;
    justify-content: baseline;
}

.partner_description,
.user_description {
    margin-bottom: 2%;
}

.description_header>i {
    color: var(--primary-green);
    padding: .5% 1.2% 0 0;
}

.about_section_advantages .about_section_title {
    text-align: center;
    width: 100%;
    padding-bottom: 4%;
}

.about_section_partners {
    padding-bottom: 8%;
}

.about_section_partners>h2 {
    font-size: 40px;
    text-align: center;
    padding-bottom: 4%;
}

.slick-track {
    display: flex !important;
}

/* About Counter */

.counter {
    width: 100%;
    background: #111;
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 0 6%;
}

.counter>h2 {
    color: var(--primary-grey);
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    padding: 5% 0 0;
    letter-spacing: 1px;
}

.counter>p {
    color: var(--primary-grey);
    text-align: center;
    font-weight: 300;
    font-size: 14px;
    padding: 0 0 5% 0;
}

.counter_container {
    display: flex;
    justify-content: space-around;
    padding-bottom: 10%;
}

.counter_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 170px;
    border-radius: 10px;
    background: var(--primary-grey);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.counter_box .number {
    font-size: 45px;
    font-weight: 700;
}

.counter_box>span {
    font-weight: 500;
    font-size: 15px;
    letter-spacing: .5px;
}


/* Gym page */

.gym_header {
    width: 100%;
    background: var(--primary-light-grey);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.gym_header>h1 {
    font-size: 48px;
    padding-top: 10%;
    padding: 10% 6% 0%;
}

.select_wrapper {
    display: flex;
    align-items: center;

}

.select-group {
    border: 1px solid #DED6D6;
    border-radius: 10px;
    width: 60%;
    height: 50px;
    background-color: var(--primary-white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.select-btn {
    padding: 10px;
    background: var(--primary-white);
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.select-line {
    border-right: 1px solid #CBCBCB;
}

.select .select-btn>span {
    font-size: 18x;
    letter-spacing: .5px;
    color: #999;
    font-weight: 500;
}

.select .select-btn>i {
    color: #999;
    padding-right: 10px;
}

.select {
    width: 250px;
    position: relative;
    user-select: none;
    cursor: pointer;
}


.options {
    position: absolute;
    top: 140%;
    left: 0;
    width: 100%;
    padding: 10px;
    background: var(--primary-white);
    border-radius: 10px;
    max-height: 140px;
    overflow-y: auto;
}

.options .option-item {
    height: 40px;
    border-radius: 5px;
    list-style: none;
    cursor: pointer;
    padding: 0 5px;
    font-size: 14px;
    transition: .3s all;
    display: flex;
    align-items: center;
}

.options .option-item:hover {
    background: #f4f4f4;
}

.options::-webkit-scrollbar {
    width: 10px;
}

.options::-webkit-scrollbar-track {
    background-color: #e7e7e7;
    background-color: #e7e7e7;
    border: 1px solid #cacaca;
    border-radius: 25px;
}

.options::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 25px;
}

.select_wrapper>.submit-btn {
    padding: 0 2%;
    height: 50px;
    width: 120px;
    background-color: var(--primary-red);
    border: none;
    border-radius: 10px;
    margin-left: 15px;
    cursor: pointer;
    transition: .3s all;
}

.select_wrapper>.submit-btn:hover {
    background: var(--primary-red-hover);
}

.select_wrapper>.submit-btn>a {
    font-size: 16px;
    color: var(--primary-white);
    font-weight: 500;
}

.select-group>.line {
    background: var(--primary-light-grey);
    height: 30px;
    width: 1px;
}

.select__container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /* justify-content: space-between; */
    /* padding: 0 6% 12%; */
    padding-bottom: 12%;
}

/* new filter */


.gym_filter {
    padding: 3% 6%;
}

.filter_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 70%;
    border-radius: 10px;
    background-color: var(--primary-white);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.f-select {
    display: flex;
    flex-direction: column;
    width: 30%;
    margin: 0 2%;
}

.f-select>label {
    color: var(--primary-grey-hover);
    letter-spacing: .5px;
}

.f-select>select {
    height: 50px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid var(--clr-grey);
    cursor: pointer;
}





.view {
    display: flex;
    align-items: center;
}

.view_icons button {
    padding: 10px;
}

.view_icons button svg {
    width: 28px;
    height: 28px;
}

/* .sortBy {

    padding: 0 10px;
} */

.sortBy-select {
    max-width: 140px;
    height: 40px;
    border-radius: 10px;
    border-color: var(--clr-grey);
    cursor: pointer;
    color: var(--primary-grey-hover);
}

/* Pagination */

.pagination {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    list-style-type: none;
    padding: 4% 0;
}

.page-link {
    padding: 20px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
}

.page-active {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--primary-red);
    height: 40px;
    width: 40px;
    border-radius: 50px;
    box-shadow: 0px 6px 19px rgba(216, 15, 45, 0.5);
}

.page-active-link {
    color: var(--primary-white);
    font-weight: 500;
}

.page-item {
    margin-right: 15px;
    cursor: pointer;
}

/* gym_section */

.gyms_section {
    width: 100%;
    height: 100%;
    padding: 5% 6% 2%;
    background-color: var(--primary-white);
}

.gyms_section_promotion {
    width: 100%;
    padding: 5% 6% 7%;
    background-color: var(--primary-light-grey);
}

.gyms_section_promotion>h2 {
    font-size: 35px;
    font-weight: 600;
    letter-spacing: .5px;
    padding-bottom: 3%;
}

.slick-slide {
    margin-right: 25px;
}


/* Price Section */

.price-hero {
    width: 100%;
    background: var(--primary-light-grey);

}

.price-hero__body {
    padding: 12% 6% 5%;
}

.price-hero__body>h1 {
    text-align: center;
    font-size: 45px;
    letter-spacing: .5px;
}

.price-hero__body>p {
    font-size: 20px;
    color: var(--primary-grey-hover);
    padding-top: 1.5%;
}

.price-box_hero {
    padding: 0 6%;
}

.price-box_title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6% 0;
    letter-spacing: .5px;
    gap: 15px;
}

.price-box_title>h3 {
    font-size: 24px;
}

.price-box_title>p {
    font-size: 18px;
    letter-spacing: 1px;
    color: var(--primary-grey-hover);
}

.price-box_wrapper {
    padding: 2% 0 6%;
    display: flex;
    justify-content: space-between;
    /* flex-wrap: wrap; */
}

/* yeni */
.price_back {
    display: flex;
    flex-direction: column;
    /* position: relative; */
}

.back_list>li {
    display: flex;
    align-items: baseline;
}

.price_back>h2 {
    text-align: center;
    padding: 4% 1% 1%;
}

.price_back>p {
    text-align: center;
    font-weight: 500;
    color: var(--primary-grey-hover);
    font-size: 18px;
    padding: 1% 1% 4%;
}

.back_list>li>i {
    color: var(--primary-green);
    padding: 0 2%;
}

.price-box_body>.price_front {
    position: absolute;
    transform: perspective(600px) rotateY(0deg);
    background: var(--primary-dark-blue);
    width: 20%;
    height: 380px;
    border-radius: 10px;
    backface-visibility: hidden;
    /* cant see the backside elements as theyre turning around */
    transition: transform .5s linear 0s;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.price-box_body>.price_back {
    position: absolute;
    transform: perspective(600px) rotateY(180deg);
    background-color: var(--primary-light-grey);
    color: #fff;
    width: 20%;
    height: 380px;
    border-radius: 10px;
    padding: 5px;
    backface-visibility: hidden;
    /* cant see the backside elements as theyre turning around */
    transition: transform .5s linear 0s;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.price-box_body:hover>.price_front {
    transform: perspective(600px) rotateY(-180deg);
}

.price-box_body:hover>.price_back {
    transform: perspective(600px) rotateY(0deg);
}



/* .back_btn {
    position: absolute;
    bottom: 0;
    right: 27%;
} */

/* bitdi */
.price-box_body {
    width: 28%;
    height: 290px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    float: left;
}

.price-box_body .name {
    font-size: 28px;
    color: var(--primary-white);
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;
    padding: 6% 0;
    text-transform: uppercase;
}

.price-box_body .info {
    color: var(--primary-white);
    padding: 1% 2%;
    text-align: center;
}

.price-box_body .price_number {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    text-shadow:
        0 0 7px #fff,
        0 0 10px #fff,
        0 0 21px #fff,
        0 0 42px #0fa,
        0 0 82px #0fa,
        0 0 92px #0fa,
        0 0 102px #0fa,
        0 0 151px #0fa;
}

.price_number>p {

    color: var(--primary-white);
    font-size: 4.2rem;
    animation: flicker 1.5s infinite alternate;
}

@keyframes flicker {

    0%,
    18%,
    22%,
    25%,
    53%,
    57%,
    100% {

        text-shadow:
            0 0 4px #fff,
            0 0 11px #fff,
            0 0 19px #fff,
            0 0 40px #0fa,
            0 0 80px #0fa,
            0 0 90px #0fa,
            0 0 100px #0fa,
            0 0 150px #0fa;

    }

    20%,
    24%,
    55% {
        text-shadow: none;
    }
}


.price_number>i {
    font-size: 30px;
    color: var(--primary-white);
}

.price_btn {
    padding: 8% 0 0;
    text-align: center;
}

.price_btn>a {
    padding: 10px 30px;
    background: var(--primary-red);
    color: var(--primary-white);
    font-size: 18px;
    font-weight: 500;
    letter-spacing: .3px;
    border-radius: 7px;
    margin-top: 30px;
    transition: .3s all;
}

.price_btn>a:hover {
    background: var(--primary-red-hover);
}

/* price section (table) */

.price_table {
    padding: 0 6% 6%;
}

.price_table .table_title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5% 0;
}

.price_table .table_title>h4 {
    font-size: 28px;
    letter-spacing: .5px;
}

.price_table .table_title>p {
    font-size: 18px;
    letter-spacing: .5px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

td {
    text-align: center;
}


table,
th,
td {
    border-bottom: 1px solid var(--primary-light-grey);
}

.table_head {
    padding: 15px;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: .5px;
}

/* .table_row {
    height: 70px;
} */

.table_row .fa-xmark {
    color: var(--primary-red);
    font-size: 24px;
}

.table_row .fa-check {
    color: var(--primary-green);
    font-size: 24px;
}

/* .feature_title {
    display: flex;
    
} */


.table_row .feature_title button {
    color: var(--primary-dark-grey);
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}





/* Price page (faq -client) */

.faq-client {
    background: var(--primary-light-grey);
}

.faq-client>h2 {
    font-size: 40px;
    letter-spacing: 1px;
    text-align: center;
    padding: 3% 0;
    color: var(--primary-dark-blue);
}

.faq-client_wrapper {
    padding: 0% 6% 6%;
    display: flex;
    flex-direction: column;

}

.faq_boxes_top,
.faq_boxes_bottom {
    display: flex;
    justify-content: space-between;
}

.faq_boxes_top {
    padding-bottom: 6%;
}

.faq-client_box {
    width: 40%;
}

.faq-client_box>h4 {
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding-bottom: 2%;
    color: var(--primary-dark-blue);
}

.faq-client_box>p {
    font-size: 16px;
    width: 85%;
    line-height: 32px;
}


/* CONTACT PAGE */

.contact-hero {
    width: 100%;
    background: var(--primary-light-grey);
}

.contact_head {
    padding: 12% 6% 6%;
}

.contact_head>h2 {
    text-align: center;
    font-size: 45px;
    letter-spacing: .5px;
}

.contact__container {
    width: 100%;
}

.contact__container .contact_title {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact__container .contact_title>h4 {
    font-size: 30px;
    padding: 6% 0 0;
    letter-spacing: 1px;
}

.contact__container .contact_title>p {
    font-size: 16px;
    letter-spacing: .5px;
    color: var(--primary-grey-hover);
    padding-top: 1%;
}

.contact__container .contact_info {
    width: 100%;
    padding: 5% 6%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contact__container .contact_info .contact_flex {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.contact__container .contact_info .contact_flex>i {
    font-size: 40px;
    color: var(--primary-red);
    padding-bottom: 7%;
}

.contact__container .contact_info .contact_flex>h4 {
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: .5px;
}

.contact__container .contact_info .contact_flex>p,
.contact__container .contact_info .contact_flex>a {
    font-size: 18px;
    letter-spacing: 1px;
    padding-top: 2%;
}


/* Contact (form) */

.contact_form {
    width: 100%;
    background: var(--primary-light-grey);
    padding: 0 6%;
}

.contact__form_hero {
    padding: 8% 0 4%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

}

.contact__form_hero form {
    width: 60%;
    padding-bottom: 4%;
    background: var(--primary-white);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact__form_hero form>h3 {
    font-size: 20px;
    font-weight: 600;
    padding: 6% 0 3%;
    letter-spacing: 2px;
}

.contact__form_hero form .text_center {
    width: 80%;
    padding: 2% 0;
}

.form-area {
    border: 1px solid var(--primary-grey);
    border-radius: 10px;
    padding: 3% 4%;
    width: 100%;
    outline: none;
    font-size: 14px;
    letter-spacing: .5px;
    font-weight: 500;
}

.contact__form_hero form>button {
    width: 80%;
    height: 70px;
    border-radius: 10px;
    background: var(--primary-red);
    border: none;
    color: white;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: .5px;
    margin-top: 20px;
    cursor: pointer;
    transition: .5s all;
}

.contact__form_hero form>button:hover {
    background: var(--primary-red-hover);
}

.text_center textarea {
    resize: none;
}

.contact__form_hero form input:focus,
.contact__form_hero form textarea:focus {
    outline: none !important;
    border-color: #719ECE;
    box-shadow: 0 0 10px #719ECE;
}

.contact_img {
    width: 60%;
    padding-bottom: 3%;
}


.contact_img img {
    width: 80%;
}

.contact_iframe {
    padding: 4% 0;
}

.contact_iframe iframe {
    border: none;
}


/* LOGIN PAGE */


/* .login_wrapper {
   
    width: 100%;
    padding: 0 6%;
    background-color: var(--primary-background-grey);
}

.login_card {
    margin-top: 30px;
    background-color: var(--primary-white);
    border-radius: 10px;
    
}
.login_card_container{
    padding: 10% 6%;
    display: flex;
    justify-content: center;
}
.login_card > form {
    width: 60%;
    padding-bottom: 8%;
    display: flex;
    flex-direction: column;
    align-items: center;

}
.login_form-group {
    width: 80%;
    padding: 2% 0;
}
.login_card > h2 {
    text-align: center;
    letter-spacing: 1px;
    color: var(--primary-red);
    padding: 6% 2%;
}
.form_phone > .phone_code-disabled {
    width: 50px;
    padding: 2% 0;
    border: none;
}
.form_phone > .form_input {
    padding: 2% 10%;
} */


.login_container {
    width: 100%;
    padding: 15% 6%;
    display: flex;
    justify-content: center;
}

.login_card_body {
    width: 50%;
    background-color: var(--primary-white);
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.login_card_body>form {
    padding: 2% 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 25px;
}

/* .login_form-group {
   padding: 2% 5%;
} */
.login_form-group {
    display: flex;
    flex-direction: column;
}

.login_form-group>label {
    font-size: 14px;
    color: var(--primary-grey-hover);
    font-weight: 500;
    padding-bottom: 1.5%;
}

.login_card_body>h2 {
    color: var(--primary-red);
    padding: 8% 2% 3%;
    text-align: center;
}

.input_flex {
    display: flex;
    border-radius: 7px;
    border: 1px solid var(--primary-grey);
}

.phone_code-disabled {
    width: 70px;
    border-radius: 7px;
    font-size: 16px;
    border: none;
    text-align: center;
    border-right: 1px solid var(--primary-grey);
}

.form_phone>.form_input {
    width: 100%;
    padding: 2% 5%;
    border: none;
    border-radius: 7px;
    font-size: 16px;
    color: var(--primary-grey-hover);
    letter-spacing: .3px;
}

.form_phone>.form_input:focus {
    outline: none !important;
    border-color: #719ECE;
    box-shadow: 0 0 10px #719ECE;
}

.login__btn {
    width: 100%;
    padding: 3% 15%;
    background: var(--primary-red);
    border-radius: 7px;
    color: var(--primary-white);
    font-weight: 600;
    letter-spacing: .5px;
    font-size: 16px;
    border: 1px solid var(--primary-red-hover);
    transition: .3s all;
}

.login__btn:hover {
    border: 1px solid var(--primary-red);
    background: var(--primary-red-hover);
}

.login_card_body>h5 {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    padding: 2% 0 10%;
    color: var(--primary-grey-hover);
}

.login_link {
    color: var(--primary-red);
    transition: .3s all;
}

.login_link:hover {
    text-decoration: underline;
    color: var(--primary-red-hover);
}

.login_img {
    width: 100%;
    height: 700px;
}

.login_img>img {
    width: 100%;
    height: 100%;
}


/* GYM DETAILED */
.hidden {
    display: none;
  }

.gym-detailed_hero {
    width: 100%;
    background: var(--primary-background-grey);
}

.gym-detailed_hero .gym-detailed_body {
    padding: 15% 6% 5%;
}

.gym-detailed_description>h1 {
    font-size: 50px;
    letter-spacing: 1px;
}

.gym-dtl_location {
    width: 35%;
    color: var(--primary-grey-hover);
}

.gym-dtl_rating {
    display: flex;
    align-items: center;
    padding-top: 1.5%;
}

.gym-dtl_rating .rating_number,
.gym_feedback__rayting>.rating_number {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--primary-red);
    width: 45px;
    height: 40px;
    border-radius: 10px;
}

.gym-dtl_rating .rating_number>span,
.gym_feedback__rayting>.rating_number>span {
    color: var(--primary-white);
    font-size: 20px;
    font-weight: 500;
}

.demo-gallery {
    width: 100%;
    max-height: 370px;
    overflow-y: hidden;
}

.scrollable::-webkit-scrollbar {
    display: none;
}

.gym-dtl_img__body {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.feedback_link {
    font-weight: 500;
    padding-left: 1%;
    color: var(--primary-red);
    text-decoration: underline;
    font-size: 20px;
}

.feedback_link:hover {
    color: var(--primary-red-hover);
}

.feedback_link>i {
    color: var(--primary-red);
    transition: .5s all;
    padding-left: 3px;
}

.feedback_link>i:hover {
    transform: translate(30px);
}

/* GYM DETAILES IMAGES */

.gym-dtl_img {
    width: 100%;
    padding: 3% 6%;
}

/* .gym-dtl_img_container {
    display: flex;
    padding: 3% 0;
} */
.lg-react-element {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(5, 5vw);
    grid-gap: 10px;
    padding: 0 0 3%;
}

.gallery__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.gallery__item--1 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
}

.gallery__item--2 {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 3;
}

.gallery__item--3 {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 6;
}

.gallery__item--4 {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 3;
    grid-row-end: 6;
}

.gallery__item--5 {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 6;
    grid-row-end: 9;
}


/* new */
.lightG {
    display: flex;
}



.border-radius_1 {
    border-radius: 10px 0 0 0;
}

.border-radius_3 {
    border-radius: 0 10px 10px 0;
}

.border-radius_4 {
    border-radius: 0 0 0 10px;
}

/* icon */
.fa-chevron-right,
.fa-chevron-left {
    font-size: 50px;
    transition: .5s all;
    padding: 0 15px;
}

.fa-chevron-right:hover,
.fa-chevron-left:hover {
    color: var(--primary-light-grey);
}

.close_img {
    font-size: 22px;
    color: var(--primary-light-grey);
}

.gym_all-btn {
    display: flex;
    justify-content: flex-end;
}

.gym-dtl_btn {
    background: var(--primary-dark-blue);
    color: var(--primary-white);
    padding: 1% 3%;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: .5px;
    border-radius: 10px;
    transition: .3s all;
}

.gym-dtl_btn:hover {
    background: #31458F;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

/* GYM DETAILED INFORMATION */

.gym-detailed_info_container {
    width: 100%;
    padding: 3% 6%;
    background: var(--primary-light-grey);
}

.gym-detailed_info_wrapper {
    display: flex;
    justify-content: space-between;
}

.gym-location_map {
    width: 158%;
}

.gym-detailed_info_main {
    width: 50%;
}

.gym-detailed_info_main>h4 {
    letter-spacing: .5px;
    text-align: center;
    font-size: 30px;
    color: var(--primary-grey-hover);
    padding-bottom: 5%;
}

.gym-detailed_about {
    display: flex;
    flex-direction: column;
    gap: 35px;
}

.gym-detailed_about_features {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.features-box {
    width: 100%;
    padding: 2% 4%;
    background: var(--primary-white);
    display: flex;
    align-items: center;
    border-radius: 10px;
}

.features-box_hover {
    border: 1px solid transparent;
    cursor: pointer;
    transition: .5s all;
}

.features-box_hover:hover {
    border: 1px solid var(--primary-red);
}

.features-box>i {
    color: var(--primary-red);
    padding-right: 10px;
}

.features-box>p {
    user-select: none;
}

.gym-detailed_social {
    display: flex;
    gap: 20px;
}

.detailed-social_media {
    width: 50px;
    height: 50px;
    background: var(--primary-white);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.detailed-social_media>a>i {
    font-size: 32px;
    color: var(--primary-red);
    transition: .3s all;
}

.detailed-social_media>a>.fa-facebook-f:hover {
    color: #3b5998;
}

.detailed-social_media>a>.fa-whatsapp:hover {
    color: #25D366;
}

.detailed-social_media>a>.fa-instagram {
    background: transparent;
    border-radius: 5px;
}

.detailed-social_media>a>.fa-instagram:hover {
    width: 35px;
    height: 35px;
    text-align: center;
    align-items: center;
    color: white;
    background: #f09433;
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
}

/* Packages */

.gym-active_packages_container {
    width: 100%;
    padding: 5% 6%;
}

.gym-active_packages_container>h2,
.team_container>h2 {
    font-size: 35px;
    letter-spacing: 1px;
    padding-bottom: 3%;
}

/* filter package */
.filter_packs {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 3%;
}


.filter_pack {
    padding: 7px 20px;
    background-color: var(--primary-light-grey);
    color: var(--primary-grey-hover);
    border-radius: 50px;
    font-size: 16px;
    font-weight: 500;
    transition: .3s all;
    /* border: 1px solid var(--primary-red); */

}

.filter_pack:hover,
.filter_pack:focus,
.filter_pack:active {
    background-color: var(--primary-red);
    color: var(--primary-white);

}

.active-packages_wrapper {
    display: flex !important;
    flex-wrap: wrap;
    align-items: center;
    gap: 50px;
}

.gym-active-package {
    width: 360px;
    height: 400px;
    background: var(--primary-white);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    border: 1px solid var(--primary-grey);
    cursor: pointer;
    transition: all .3s;
}

.gym-active-package:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transform: translate(0, -20px);
}

.package_price {
    margin-top: 34px;
    width: 70%;
    height: auto;
    padding: 2%;
    background: var(--primary-red);
    border-radius: 0 50px 50px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.package_price>.active_packages__name,
.package_price>.active_packages__price {
    text-align: center;
    font-size: 18px;
    color: var(--primary-white);
    font-weight: 600;
    letter-spacing: .5px;
}

.package_price__list>.package_price__list--item {
    padding: 2%;
    display: flex;
}

.package_price__list--item>i {
    padding: 5px 7px 0 0;
    color: var(--primary-red);
}

.package_price__list--item>span {
    font-weight: 600;
    font-size: 18px;
    letter-spacing: .2px;
}

.package_price__list--item>p {
    font-size: 18px;
    padding-left: 2%;
}

.gym-active-package>.package_price--button {
    display: flex;
    justify-content: center;
}

.gym-active-package>.package_price--button>button {

    padding: 15px 50px;
    background: #F2F2F2;
    border-radius: 10px;
    border: 1.5px solid transparent;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: .5px;
    transition: all .5s;
}

.gym-active-package>.package_price--button>button:hover {
    /* background: var(--primary-red);
    color: var(--primary-white); */
    border: 1.5px solid var(--primary-dark-blue);
}

/* endirim qiymeti */
.active_packages__discount_percentage {
    font-size: 18px;
    font-weight: 500;
}

.active_packages__discounted {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.active_packages__original_price {
    font-size: 16px;
    font-weight: normal;
    text-decoration: line-through;
    color: var(--primary-light-grey) !important;
}

.active_packages__discounted_price {
    font-size: 18px;
    color: var(--primary-white);
    font-weight: 600;
}

/* .active_packages__discount_percentage {
    position: absolute;
    font-size: 14px;
    top: 10px;
    right: 10px;
    padding: 2%;
    border-radius: 7px;
    font-weight: 500;
    background-color: #D7B90F;
} */


/*  */
.active-packages_all__btn>button {
    border: 1px solid var(--primary-dark-blue);
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
    padding: 15px 25px;
    transition: all .3s;
}

.active-packages_all__btn>button:hover {
    background: var(--primary-dark-blue);
    color: var(--primary-white);
}

.active-packages_all__btn {
    display: flex;
    justify-content: center;
    padding-top: 2%;
}

.chips {
    padding: 0 0 4% 0;
}

.team_container {
    width: 100%;
    padding: 2% 6%;
    background-color: var(--primary-background-grey);
}

.team_wrapper {
    padding: 7% 0;
    display: flex;

}

.team_box {
    height: 250px;
    border: 1px solid var(--primary-grey);
    border-radius: 10px;
    background: var(--primary-white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

}

.team-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 100px 30px;
}

.tooltip_team>.fa-info {
    position: absolute;
    top: 6%;
    right: 6%;
    background-color: rgba(168, 164, 164, 0.3);
    padding: 10px 15px;
    border-radius: 10px;
    color: #fff;
    font-size: 20px;
}

.tooltip_team>.fa-info:hover {
    background-color: var(--primary-red-hover);
}

.tooltip_team:hover .tooltipText {
    opacity: 1;
    transform: translateY(-7px);
}

.tooltip_team {

    cursor: pointer;
}

.tooltipText {
    background-color: #fff;
    position: absolute;
    bottom: 130%;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 14px;
    opacity: 0;
    transition: all .5s;
    max-height: 100px;
    overflow-y: scroll;
}

.tooltipText::after {
    content: '';
    border-width: 5px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
    position: absolute;
    top: 100%;
    left: 40%;
    margin-left: 5%;

}

.team_img {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    position: absolute;
    top: -70px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.team_img>img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-size: cover;
}

.team_description {
    padding: 27% 2% 2% 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.team_description>p {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: .5px;
}

.team_description>span {
    font-size: 14px;
    color: #939393;
    padding: 2% 0;
    text-align: center;
}

.team_sosial-media>a>i {
    font-size: 30px;
    padding: 5px;
    cursor: pointer;
    transition: .3s all;
}

.team_sosial-media>a>i:hover {
    color: var(--primary-red);
}

.slick-track {
    display: flex !important;
}


/* USER INTERFACE DETALIED */

.user-interface_hero,
.control-panel_hero,
.operator-panel_hero,
.faq_hero {
    width: 100%;
    padding: 12% 6% 8%;
    background: var(--primary-light-grey);
}

.user-interface_body>h1,
.control-panel_body>h1,
.operator-panel_body>h1,
.faq_body>h1 {
    font-size: 40px;
    letter-spacing: .5px;
    text-align: center;
    padding: 2%;
}

.user-interface_body>p,
.control-panel_body>p,
.operator-panel_body>p {
    font-size: 18px;
    text-align: center;
    width: 80%;
    color: var(--primary-grey-hover);
}

.user-interface_body,
.control-panel_body,
.operator-panel_body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.user-interface_stages {
    width: 100%;
    padding: 4% 6%;
}


.registration-stage,
.search-stage,
.qr-stage {
    display: flex;
    justify-content: space-between;
}

.registration-stage>.__description,
.search-stage>.__description,
.package-stage>.__description,
.qr-stage>.__description {
    width: 45%;
    padding-bottom: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.registration-stage>.__description>p,
.search-stage>.__description>p,
.package-stage>.__description>p,
.qr-stage>.__description>p {
    font-size: 38px;
    padding: 2%;
    font-weight: 500;
    width: 90%;
    letter-spacing: .2px;
    /* line-height: 70px; */
    text-align: center;
}

.search-stage>.__description>span,
.qr-stage>.__description>span {
    font-size: 18px;
    width: 70%;
    text-align: center;
    color: var(--primary-grey-hover);
    line-height: 35px;
}

.registration-stage>.__image {
    width: 50%;
    height: 450px;
    background: var(--primary-background-grey);
    border-radius: 10px;
    position: relative;
}

.registration-stage>.__image>img {
    width: 100%;
    position: absolute;
    top: -30%;
}

.search-stage {
    padding: 4% 0;
}

.search-stage>.__image {
    width: 60%;
    height: 450px;
}

.search-stage>.__image>img {
    width: 100%;
}

.information-stage {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 6% 0%;
}

.information-stage>.__description {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 3%;
}

.information-stage>.__description>p {
    font-size: 40px;
    width: 70%;
    font-weight: 500;
    letter-spacing: .2px;
    text-align: center;
}

.information-stage>.__description>span {
    text-align: center;
    width: 70%;
    color: var(--primary-grey-hover);
    font-size: 18px;
    padding-top: 1%;
}

.information-stage>.__image {
    width: 100%;
    height: 50%;
    border-radius: 10px;
}

.information-stage>.__image>img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.package-stage {
    display: flex;
    justify-content: space-between;
}

.package-stage>.__image {
    width: 60%;

}

.package-stage>.__image>img {
    width: 100%;
}

.package-stage>.__description {
    width: 40%;
}

.package-stage>.__description>span {
    text-align: center;
    width: 100%;
    line-height: 35px;
    font-size: 18px;
    color: var(--primary-grey-hover);
}

.qr-stage>.__image {
    width: 50%;
    height: 450px;
    background: var(--primary-background-grey);
    border-radius: 10px;
    position: relative;
}

.qr-stage>.__image>img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}



/* SOFTWARE PANEL */

.functionality-section {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5% 6%;
}

.functionality-section>.__description {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.functionality-section>.__description>h3,
.class-section>.__description>h3,
.customer-section>.__description>h3,
.finance-section>.__description>h3,
.campaign-section>.__description>h3 {
    font-size: 40px;
    letter-spacing: .5px;
    text-align: center;
    padding-bottom: 3%
}

.functionality-section>.__description>h3>span,
.class-section>.__description>h3>span,
.customer-section>.__description>h3>span {
    color: var(--primary-dark-grey);
}

.functionality-section>.__description>p,
.class-section>.__description>p,
.customer-section>.__description>p,
.operator-web-section>.__description>p,
.finance-section>.__description>p,
.campaign-section>.__description>p,
.operator-mobil-section>.__description>p {
    text-align: center;
    width: 80%;
    color: var(--primary-grey-hover);
    line-height: 30px;
    font-size: 18px;
}

.functionality-section>.__image {
    width: 50%;
    background: var(--primary-background-grey);
    height: 450px;
    position: relative;
    border-radius: 10px;
}

.functionality-section>.__image>img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: -20%;
}

.class-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 5% 0;
    align-items: center;
}

.class-section_control {
    padding: 0 6%;
}

.class-section>.__description,
.customer-section>.__description,
.finance-section>.__description,
.campaign-section>.__description {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.class-section>.__description>span {
    color: var(--primary-red);
    font-weight: 700;
    letter-spacing: .5px;
    padding-top: 2%;
    font-size: 18px;
    text-transform: uppercase;
}

.class-section>.__image {
    width: 50%;
    height: auto;
    border-radius: 10px;
}

.class-section>.__image>img {
    width: 100%;
    width: 100%;
    border-radius: 10px;
}

.finance-section {
    width: 100%;
    padding: 6% 6%;
    background: var(--primary-background-grey);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.finance-section>.__image,
.campaign-section>.__image {
    width: 50%;
    height: auto;
}

.finance-section>.__image>img,
.campaign-section>.__image>img {
    width: 100%;
    height: 100%;
}

.campaign-section {
    width: 100%;
    padding: 6% 6%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.customer-section {
    width: 100%;
    padding: 6% 6%;
    background: var(--primary-background-grey);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.customer-section>.__image {
    width: 50%;
    height: auto;
}

.customer-section>.__image>img {
    width: 100%;
    height: 100%;
}


.operator-web_advantages {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 8% 6%;
}

.user-panel_advantages {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 6% 7%;
    gap: 40px;
}

.user-panel_advantages>.user-panel_advantage {
    width: 350px;
    /* height: 250px; */
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.operator-web_advantages>.operator-panel_advantage {
    width: 300px;
    /* height: 250px; */
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.user-panel_advantages>.user-panel_advantage>i,
.operator-web_advantages>.operator-panel_advantage>i {
    font-size: 32px;
    color: var(--primary-red);
    padding: 8% 1% 6% 7%;
}

.user-panel_advantages>.user-panel_advantage>h4,
.operator-web_advantages>.operator-panel_advantage>h4 {
    font-size: 20px;
    padding: 1% 1% 2% 7%;
    letter-spacing: .5px;
    user-select: none;
}

.user-panel_advantages>.user-panel_advantage>p,
.operator-web_advantages>.operator-panel_advantage>p {
    font-size: 16px;
    padding: 0 7% 5% 7%;
    letter-spacing: .2px;
    color: var(--primary-grey-hover);
    user-select: none;
}

.more-detailed {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3% 6%;
    background: var(--primary-red);
    margin-bottom: 5%;
}

.more-detailed>h2 {
    color: var(--primary-white);
    letter-spacing: .5px;
    font-weight: 600;
    font-size: 30px;
    padding-right: 2%;
}

.more-detailed>button {
    background: var(--primary-white);
    color: var(--primary-red);
    font-weight: 700;
    font-size: 18px;
    letter-spacing: .5px;
    border-radius: 10px;
    padding: 16px 32px;
    border: 1px solid transparent;
    transition: .3s all;
}

.more-detailed>button:hover {
    border: 1px solid var(--primary-red-hover);
}

/* OPERATOR PANEL */

.operator-web-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4% 6% 0;
}

.operator-web-section>.__description {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.operator-web-section>.__description>h3 {
    text-align: center;
    font-size: 40px;
    letter-spacing: .5px;
    padding-bottom: 3%
}

.operator-mobil-section>.__description>h3 {
    text-align: center;
    font-size: 35px;
    letter-spacing: .5px;
    padding-bottom: 3%
}

.operator-web-section>.__description>h3>span,
.operator-mobil-section>.__description>h3>span {
    color: var(--primary-dark-grey);
}

.operator-web-section>.__image {
    width: 50%;
    height: auto;
    border-radius: 10px;
}

.operator-web-section>.__image>img {
    width: 100%;
    height: 100%;
}

.operator-mobil-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 6% 5%;
}

.operator-mobil-section>.__image {
    width: 50%;
    height: auto;
    border-radius: 10px;
}

.operator-mobil-section>.__image>img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.operator-mobil-section>.__description {
    text-align: center;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.operator-mobil-section>.__description p {
    font-size: 18px;
    color: var(--primary-grey-hover);
    padding: .5%;

}

/* FAQ */
.faq_container {
    width: 100%;
    padding: 0 6% 6% 6%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
}

.faq_main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.faq_description {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4% 0 0 0;
}

.faq_description>h2 {
    letter-spacing: .5px;
    font-size: 35px;
}

.faq_description>p {
    color: var(--primary-grey-hover);
    font-size: 18px;
}

.faq_accordion {
    width: 70%;
    background: var(--primary-light-grey);
    padding: 1% 2%;
    border-radius: 10px;
    transition: .3s all;
}

.faq_accordion:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.faq_accordion:focus {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.faq_question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.faq_question>h2 {
    font-size: 18px;
    font-weight: 600;
}

.faq-color {
    color: var(--primary-dark-grey);
    transition: all .2s
}

.faq-color.show {
    color: var(--primary-red);
    transition: all .2s
}

.faq_question>i {
    color: var(--primary-red);
}

.faq_content {
    color: var(--primary-grey-hover);
    max-height: 0;
    overflow: hidden;
    transition: all .5s cubic-bezier(0, 1, 0, 1);
}

.faq_content>p {
    padding: 3% 0;
}

.faq_content.show {
    height: auto;
    max-height: 9999px;
    transition: all .5s cubic-bezier(1, 0, 1, 0);
}

.rotate {
    -moz-transition: all .5s linear;
    -webkit-transition: all .5s linear;
    transition: all .5s linear;
}

.rotate.down {
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

/* FEEDBACK */

.rating__hero {
    width: 100%;
    padding: 12% 6% 5%;
    background-color: var(--primary-light-grey);
}

.rating__hero>h1 {
    font-size: 50px;
    letter-spacing: 1px;
}

.gym_feedback__container {
    width: 100%;
    padding: 5% 6%;
    display: flex;
    flex-direction: column;
    gap: 60px;
}

.gym_feedback__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gym_feedback__hero>h2 {
    font-size: 35px;
    letter-spacing: 1px;
    padding-bottom: 2%;
}

.gym_feedback__main {
    display: flex;
    flex-direction: column;
}

.gym_feedback__main>p {
    font-size: 18px;
    font-weight: 600;
}

.gym_feedback__main>span {
    color: var(--primary-grey-hover);
}

.gym_feedback__main .feedback__date {
    margin-top: 10px;
    font-size: 14px;
    letter-spacing: .5px;
}

.gym_feedback__rayting {
    display: flex;
    align-items: center;
}

.gym_feedback__rayting>.rating_number {
    margin-right: 10px;
}

.gym_feedback__wrapper>.gym_feedback__ {
    width: 100%;
    height: auto;
    background: var(--primary-background-grey);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    padding: 1% 2%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px;

}

.gym_feedback__wrapper>.gym_feedback__>.gym_feedback_rating {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.like_number {
    display: flex;
    justify-content: end;
}

.like_number>span {
    color: var(--primary-grey-hover);
    font-weight: 500;
}

.gym_feedback_rating>.like_number>i {
    display: flex;
    color: var(--clr-grey);
    font-size: 20px;
    margin-left: 5px;
}

.gym_feedback__wrapper>button {
    padding: 2% 0 0 0;
    font-size: 18px;
    text-align: center;
    font-weight: 600;
    color: var(--primary-red);
    letter-spacing: .5px;
    text-decoration: underline;
    transition: .3s all;
}

.gym_feedback__wrapper>button:hover {
    color: var(--primary-red-hover);
}


/* TERMS & CONDITIONS */

.terms-hero {
    width: 100%;
    background: var(--primary-light-grey);

}

.terms-hero_body {
    padding: 12% 6% 5%;
}

.terms-hero_body>h1 {
    text-align: center;
    font-size: 45px;
    letter-spacing: .5px;
}

.terms-texts {
    padding: 0 6%;
}

.terms-text h3 {
    padding: 2% 0;
}

.terms-text p {
    line-height: 35px;
}

.terms-text p>a {
    color: var(--primary-dark-blue);
    text-decoration: underline;
}

/* 404 PAGE NOT FOUND */

.pageNotFound {
    width: 100%;
    padding: 0 6%;

}

.pageNotFound_hero {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 8% 0;
}

.pageNotFound_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.pageNotFound_body>.pageNotFound_text h1 {
    font-size: 80px;
}

.pageNotFound_text {
    text-align: center;
    padding: 2% 0;
}

.pageNotFound_text>.text {
    text-transform: uppercase;
    font-weight: 500;
}

.pageNotFound_body>button {
    padding: 2.5% 5%;
    border-radius: 7px;
    background: var(--primary-red);
    transition: .5s all;
}

.pageNotFound_body>button:hover {
    background: var(--primary-red-hover);
}

.pageNotFound_body>button>a {
    color: var(--primary-white);
    font-size: 16px;
    font-weight: 500;
}

.pageNotFound_hero .animation  {
    width: 500px;
    height: 500px;
}