@media screen and (min-width: 1900px) {
    .search_form {
        position: absolute;
        top: 11%;
        height: 60px;
        left: 0;
        padding-left: 15%;
        right: 40%;
    }

}

@media screen and (min-width: 1600px) {
    .search_form {
        position: absolute;
        top: 11%;
        height: 60px;
        left: 0;
        padding-left: 15%;
        right: 28%;
    }
}

@media screen and (min-width: 1400px) {
    .search_form {
        position: absolute;
        top: 11%;
        height: 60px;
        left: 0;
        padding-left: 15%;
        right: 32%;
    }
}

@media screen and (max-width: 1300px) {
    .search_form {
        position: absolute;
        top: 11%;
        height: 60px;
        left: 0;
        padding-left: 15%;
        right: 31%;
    }
}

@media screen and (min-width: 1500px) {
    .search_form {
        position: absolute;
        top: 11%;
        height: 60px;
        left: 0;
        padding-left: 15%;
        right: 32%;
    }
}

@media (max-width:1200px) {

    /* Navbar */


    .main-menu_item {
        font-size: 14px;
    }

    .nav_search__toggle>.search_icon {
        font-size: 16px;
    }

    .nav-link-right>a {
        font-size: 14px;
    }

    .nav-link-left>button {
        font-size: 14px;
    }

    /* .search_form {
        position: absolute;
        top: 11%;
        height: 60px;
        left: 0;
        padding-left: 15%;
        right: 34%;
    } */
    .search_form {
        position: absolute;
        top: 10%;
        height: 60px;
        left: 0;
        padding-left: 15%;
        right: 33%;
    }

    /* ABOUT PAGE */

    .partner_main>h4,
    .user_main>h4 {
        font-size: 28px;
    }

    .partner_img,
    .user_img {
        width: 494px;
        height: 380px;
    }

    .partner_description>p,
    .user_description>p {
        font-size: 14px;
        width: 100%;
    }

    /* GYM PAGE */

    .select_wrapper>.submit-btn>a {
        font-size: 16px;
    }

    .price-box_body>.price_front {
        width: 20%;
        height: 400px;
    }

    .price-box_body>.price_back {
        width: 20%;
        height: 400px;
    }

    .price-hero__body {
        padding: 15% 6% 10%;
    }

    .price-hero__body>h1 {
        font-size: 35px;
    }

    .price-hero__body>p {
        font-size: 18px;
        color: var(--primary-grey-hover);
        padding-top: 1.5%;
    }

    /* PRICE PAGE */

    .price-box_wrapper .price-box_body .info {
        font-size: 14px;
    }

    .price-box_wrapper .price-box_body .name {
        font-size: 22px;
    }

    /* SOFTWARE PANEL */
    .functionality-section>.__description>h3,
    .class-section>.__description>h3,
    .customer-section>.__description>h3,
    .campaign-section>.__description>h3,
    .finance-section>.__description>h3 {
        font-size: 35px;
    }

    .functionality-section>.__image {
        width: 50%;
        background: var(--primary-background-grey);
        height: 400px;
        position: relative;
        border-radius: 10px;
    }

    .more-detailed>h2 {
        font-size: 28px;
    }

    .team_img {
        width: 150px;
        height: 150px;
    }

}


@media (max-width:1160px) {
    /* navbar */

    /* .nav-logo {
        display: block;
        width: 110px;
        margin-top: 10px
    }

    .mobile-menu-icon {
        display: flex;
        position: absolute;
        top: 15px;
        right: 25px;
        font-size: 30px;
    }
    .nav-link-left {
        visibility: hidden;
    }

    .nav-link-right {
        display: none;
    }
    .navbar-search {
        display: none;
    }
    .nav-links-mobile {
        display: flex;
        flex-direction: column;
        background-color: var(--primary-light-grey);
        position: fixed;
        top: 0;
        left: 0;
        transition: all .5s ease-out;
        width: 100%;
        height: 100%;
        padding: 50px 60px;
    }
  
    .main-menu_item {
        font-size: 26px;
        letter-spacing: .5px;
    }

    .nav-link-left ul {
        display: flex;
       
        list-style: none;
    }

    .search_form {
        position: absolute;
        top: 11%;
        height: 60px;
        left: 0;
        padding-left: 15%;
        right: 33%;
    } */

    /* son navbar */
    .fa-times {
        z-index: 999;
    }

    .navbar {
        padding: 0 4%;
    }

    .nav-logo {
        display: block;
        margin-top: 10px;
        position: absolute;
        left: 15%;
        bottom: 7px;
    }

    .logo {
        width: 100px;
    }

    .nav-link-right_mob {
        display: block;

    }

    .mobile-menu-icon {
        display: flex;
        position: absolute;
        top: 19px;
        font-size: 30px;
    }

    .links_mobil {
        display: flex;
        align-items: center;
    }

    .links_mobil>li {
        padding: 2px 6px;
    }

    .search_mob>i,
    .fa-user {
        font-size: 20px;
        color: var(--clr-grey);
        transition: .3s all;
    }

    .search_mob>i:hover,
    .fa-user:hover {
        color: var(--primary-red);
    }

    .search_mob {
        cursor: pointer;
    }

    .nav-link-left {
        visibility: hidden;
    }

    .nav-link-right {
        display: none;
    }

    .navbar-search {
        display: none;
    }

    .nav-links-mobile {
        display: flex;
        flex-direction: column;
        background-color: var(--primary-light-grey);
        position: fixed;
        top: 0;
        left: 0;
        transition: all .5s ease-out;
        width: 100%;
        height: 100%;
        padding: 50px 60px;
    }

    .main-menu_item {
        font-size: 26px;
        letter-spacing: .5px;
    }

    .nav-link-left ul {
        display: flex;

        list-style: none;
    }

    .search_form {
        position: absolute;
        top: 11%;
        height: 60px;
        left: 0;
        padding-left: 15%;
        right: 33%;
    }


    /* USER INTERFACE DETALIED */
    .registration-stage>.__image {
        width: 50%;
        height: 350px;
        position: relative;
    }

    .registration-stage>.__image>img {
        width: 90%;
        height: auto;
        position: absolute;
        top: -30%;
        left: 7%;
    }

    .registration-stage>.__description>p,
    .search-stage>.__description>p,
    .package-stage>.__description>p,
    .qr-stage>.__description>p {
        font-size: 34px;
    }

    /* SOFTWARE PANEL */
    .functionality-section>.__image {
        height: 350px;
        position: relative;
    }

    .functionality-section>.__image>img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: -10%;
    }

    .functionality-section>.__description>p,
    .class-section>.__description>p,
    .customer-section>.__description>p,
    .finance-section>.__description>p,
    .campaign-section>.__description>p {
        font-size: 16px;
    }

    .user-panel_advantages>.user-panel_advantage {
        width: 300px;
        height: 370px;
    }

    .operator-web_advantages>.operator-panel_advantage {
        width: 250px;
        height: 300px;
    }

    .user-panel_advantages>.user-panel_advantage>h4,
    .operator-web_advantages>.operator-panel_advantage>h4 {
        font-size: 18px;
        padding: 1% 1% 6% 7%;
    }

    .more-detailed>h2 {
        font-size: 24px;
    }

    .more-detailed {
        padding: 2% 6%;
    }

    .more-detailed>button {
        padding: 10px 32px;
    }

    /* OPERATOR PANEL */
    .operator-web-section>.__description>h3,
    .operator-mobil-section>.__description>h3 {
        font-size: 35px;
    }

    /* Table page */
    .table_head {
        font-size: 18px;
    }

    /* PRICE */
    /* .price-box_wrapper {
        
        flex-wrap: wrap;
    } */
    .price-box_wrapper {
        padding: 2% 0 17%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 150px;
    }

    .price-box_body>.price_front {
        width: 40%;

    }

    .price-box_body>.price_back {
        width: 40%;



    }

    .price_btn {
        padding: 8%;
        text-align: center;
    }

    /* .price-box_wrapper {
        padding: 2% 0 6%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 150px;
    }

  
    .price-box_body>.price_front {
        width: 60%;
        
    }

    .price-box_body>.price_back {
        width: 60%;
       

    }

    .price-box_wrapper .price-box_body .name {
        font-size: 28px;
        padding-top: 2%;
    }

    .price-box_wrapper .price-box_body .info {
        font-size: 18px;
    } */

    .back_list li span {
        font-size: 16px;
    }

    .why-us>h2 {
        font-size: 35px;
    }

    /* GYM DETAILED */
    .gym-detailed_map {
        width: 50%;
    }

    .gym-location_map {
        width: 100%;
    }

    .gym-detailed_info_main>h4 {
        font-size: 25px;
    }

    .gym-detailed_info_main {
        width: 45%;
    }

    /* FEEDBACK */
    .rating__hero {
        padding: 15% 6% 7%;
    }

    .rating__hero>h1 {
        font-size: 45px;
    }

    .gym_feedback__hero>h2 {
        font-size: 30px;
        padding-bottom: 3%;
    }

    .team_box {
        height: 220px;
    }

    .team_description {
        padding: 18% 2% 2% 2%;
    }

    .team_img {
        width: 130px;
        height: 130px;
    }

    .team_container>h2 {
        font-size: 35px;
        letter-spacing: 1px;
        padding: 3% 0 5%;
    }

}

@media (max-width:1105px) {
    .nav-link-right_mob {
        display: block;
        margin-bottom: 40px;
    }

}

@media (max-width:992px) {
    .nav-link-left {
        display: flex;
        align-items: center;
        width: 50%;
    }

    /* header */
    .head-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 65%;
        padding-left: 6%;
        padding-bottom: 10%;
    }

    .head-text>h1 {
        font-size: 35px;
        line-height: 65px;
    }

    .head-photo img {
        padding-top: 30%;
        width: 60%;
        height: auto;
    }

    /* partner */
    .slide>img {
        width: 100px;
    }

    .partner>img {
        width: 100px;
    }

    .power img {
        height: 100px;
    }

    /* advantages */

    .advantages>h2 {
        font-size: 30px;
    }

    .benefit__title>h4 {
        font-size: 16px;
    }

    .adv-text p {
        font-size: 14px;
    }

    .benefits {
        margin-top: 1rem;
    }

    /* gyms */
    .gyms {
        width: 100%;
        height: 100%;
        padding: 0 6%;
    }

    .gym-container {
        height: 350px;
        width: 308px;
    }

    .gym-img img {
        width: 100%;
        height: 100%;
        background-size: cover;
    }

    .location,
    .feedback {
        font-size: 14px;
    }

    .gym-container {
        max-width: 768px;
        height: auto;
        margin: auto;
        margin-bottom: 1rem;
    }

    .f-select>label {
        font-size: 14px;
    }

    .filter_wrapper {

        width: 100%;

    }

    /* Advantages */
    .adv-text>p {
        font-size: 12px;
    }

    .benefit>a {
        font-size: 14px;
    }

    .benefit {
        padding-right: 2%;
    }


    /* why us */

    .why-us>h2 {
        font-size: 35px;
    }

    .why-us_description>p {
        font-size: 14px;
        padding: 3% 0;
    }

    .why-us_btn a {
        font-size: 16px;
        transition: .8s all;
    }

    .why-us_img .rectangle {
        position: absolute;
        left: 20%;

        width: 50%;
        height: 100%;
        background: linear-gradient(90deg, rgba(26, 41, 128, 0.45) 0%, rgba(38, 208, 206, 0.45) 100%);
    }

    /* testimonials */
    .testimonials-header>h2 {
        font-size: 35px;
    }

    /* .card {
    width: 300px;
    height: auto;
} */
    .card>.details {
        font-size: 14px;
        line-height: 27px;
        width: 95%;
        padding: 18% 2% 5% 10%;

    }

    .card>.quotation-mark>img {
        width: 34px;
    }

    .user-name>span {
        font-size: 14px;
    }

    /* discover */
    .discover {
        display: none;
    }


    /* PACKAGE */

    .packages {
        padding-bottom: 7%;
    }

    .price-table h2 {
        font-size: 35px;
        padding: 4% 0;
    }

    .package {
        width: 210px;
        height: 400px;
    }

    .package-title .name {
        font-size: 16px;
    }

    .package-title .info {
        font-size: 14px;
        text-align: center;
    }

    .package-title {
        width: 100%;
        height: auto;
    }

    .details>p {
        font-size: 13px;
    }

    .details:first-child {
        padding-top: 7%;
    }



    /* PROMOTION */

    .promotion-video {
        width: 70%;
        height: auto;
    }

    .promotion-main h2 {
        font-size: 35px;
    }

    .promotion-main p {
        width: 80%;
        font-size: 16px;
    }

    .prm-buttons .app-store {
        margin-right: 15px;
        width: 120px;
        transition: .5s all;
    }

    .prm-buttons .play-market {
        width: 120px;
        transition: .5s all;
    }

    /* footer */
    .sp-logo img {
        width: 125px;
    }

    .sp-logo>p {
        font-size: 12px;
    }

    .footer-title {
        font-size: 14px;
        padding: 3% 0;
    }

    .sport>a,
    .company>a,
    .contact>a {
        font-size: 12px;
    }

    .icons>a>i {
        font-size: 20px;
    }

    .footer-content p {
        font-size: 12px;
    }

    /* About section */

    .about-text>h1 {
        font-size: 30px;
    }

    .about-text .txt-description {
        width: 60%;
    }

    .partner_advantages h2,
    .user_advantages h2 {
        font-size: 30px;
    }

    .partner_main>h4,
    .user_main>h4 {
        font-size: 30px;
        padding-bottom: 4%;
        text-align: center;
    }

    .partner_body,
    .user_body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;
    }

    .partner_description>p,
    .user_description>p {
        width: 100%;
        padding-top: 2%;
    }

    .partner_advantages h2,
    .user_advantages h2 {
        padding-bottom: 1%;
    }

    .partner_main,
    .user_main {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
    }


    .img-container {
        width: 100%;
        height: 474px;
    }

    .partner_img,
    .user_img {
        width: 494px;
        height: 380px;
    }

    /* GYM PAGE */

    .select_wrapper>.submit-btn>a {
        font-size: 14px;
    }

    .select .select-btn>span {
        font-size: 14px;
    }

    .options .option-item {
        font-size: 14px;
    }

    .gyms_section_promotion>h2 {
        font-size: 30px;
    }

    .gym_header {
        gap: 20px;
    }

    .gym_header>h1 {
        font-size: 35px;
        padding: 15% 6% 0%;
    }

    .select_wrapper {
        display: flex;
        flex-direction: column;
    }

    .select .select-btn>i {
        color: #999;
        font-size: 12px;
        padding: 0 5px;
    }

    /* PRICE PAGE */
    .price-box_wrapper .price-box_body .name {
        font-size: 20px;
    }

    .price-box_title {
        text-align: center;
    }

    .price-box_title h3 {
        font-size: 26px;
    }

    .price_btn>a {
        padding: 5px 20px;
        font-size: 16px;
    }

    .price_number>p {
        font-size: 45px;
        color: var(--primary-white);
    }

    .price_number>i {
        font-size: 26px;
        color: var(--primary-white);
    }

    .back_list>li>span {
        font-size: 14px;
    }

    .price_front {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    /* .back_btn {
        position: absolute;
        bottom: 0;
        right: 25%;
    } */

    /* CONTACT PAGE */
    .contact_head>h2 {
        text-align: center;
        font-size: 35px;
        letter-spacing: .5px;
    }

    .contact__container .contact_title>h4 {
        font-size: 25px;
    }

    .contact__container .contact_info .contact_flex>h4 {
        font-size: 16px;
    }

    .contact__container .contact_info .contact_flex>p,
    .contact__container .contact_info .contact_flex>a {
        font-size: 16px;
        letter-spacing: 1px;
        padding-top: 2%;
    }

    .contact__container .contact_info .contact_flex>i {
        font-size: 30px;
        color: var(--primary-red);
        padding-bottom: 10%;
    }

    .contact__container {
        padding: 3% 0;
    }

    .form-area {
        padding: 5% 4%;
        font-size: 12px;
    }

    .contact__form_hero form>button {
        height: 50px;
        font-size: 16px;
    }

    .contact__form_hero form>h3 {
        font-size: 18px;
    }


    /* USER INTERFACE DETALIED */
    .user-interface_stages {
        width: 100%;
        padding: 8% 6%;
    }

    .user-interface_body>h1,
    .control-panel_body>h1,
    .operator-panel_body>h1,
    .faq_body>h1 {
        font-size: 35px;
    }

    .registration-stage>.__image {
        width: 50%;
        height: 250px;
        position: relative;
    }

    .registration-stage>.__image>img {
        width: 80%;
        height: auto;
        position: absolute;
        top: -30%;
        left: 9%;
    }

    .registration-stage>.__description>p,
    .search-stage>.__description>p,
    .package-stage>.__description>p,
    .qr-stage>.__description>p {
        font-size: 30px;
    }

    .search-stage {
        padding: 3% 0 2% 0;
    }

    .search-stage>.__image {
        width: 60%;
        height: 350px;
    }

    .information-stage>.__description>p {
        font-size: 30px;
    }

    .qr-stage>.__image {
        height: 350px;
    }

    /* SOFTWARE PANEL */
    .functionality-section>.__description>h3,
    .class-section>.__description>h3,
    .customer-section>.__description>h3 {
        font-size: 30px;
    }

    .functionality-section>.__image {
        height: 280px;
        position: relative;
    }

    .functionality-section>.__image>img {
        position: absolute;
        top: -7%;
    }

    .user-interface_hero,
    .control-panel_hero,
    .operator-panel_hero,
    .faq_hero {
        width: 100%;
        padding: 20% 6% 12%;
    }

    .class-section>.__description>p {
        width: 90%;
    }

    .class-section>.__description>span {
        font-size: 16px;
    }

    .user-panel_advantages,
    .operator-web_advantages {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
    }

    .more-detailed>h2 {
        font-size: 22px;
    }

    /* SOFTWARE PANEL */
    .operator-web-section>.__description>h3,
    .operator-mobil-section>.__description>h3 {
        font-size: 25px;
    }


    .operator-web-section>.__description>p,
    .operator-mobil-section>.__description>p {
        font-size: 16px;
        width: 100%;
        padding: 2%;
    }

    /* Table */
    .table_head {
        font-size: 16px;
    }

    .table_row .feature_title button {
        font-size: 16px;
        text-align: right;
    }


    /* TERMS */
    .terms-hero_body {
        padding: 16% 6% 7%;
    }

    .terms-hero_body>h1 {
        font-size: 35px;
    }


    /* LOGIN PAGE */
    .login_card_body {
        width: 70%;
    }

    .form-search_inner .search-input {
        font-size: 14px;
    }

    /* filter package */


    .filter_packs {
        margin-bottom: 5%;
    }


    .filter_pack {
        padding: 1% 2%;
        font-size: 14px;
    }

    .active-packages_wrapper {
        display: flex !important;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        /* gap: 20px; */
    }

    .gym-active-package {
        width: 320px;
        height: 330px;
        gap: 25px;
    }

    /* .package_price {
        width: 50%;
        height: 50px;
    } */

    .package_price>.active_packages__name,
    .package_price>.active_packages__price {
        font-size: 16px;
    }

    .package_price__list--item>span {
        font-weight: 600;
        font-size: 16px;
        letter-spacing: .2px;
    }

    .package_price__list--item>p {
        font-size: 16px;
        padding-left: 2%;
    }

    .gym-active-package>.package_price--button>button {
        padding: 3% 7%;
        font-size: 16px;
    }

    /* GYM DETAILED */
    .gym-detailed_map {
        width: 50%;
    }

    .gym-location_map {
        width: 100%;
    }

    .gym-detailed_info_main>h4 {
        font-size: 22px;
    }

    .gym-detailed_info_main {
        width: 45%;
    }

    .features-box>p {
        font-size: 14px;
    }

    /* GYM DETAILED */
    .gym-detailed_map {
        width: 50%;
    }

    .gym-location_map {
        width: 100%;
    }

    .gym-detailed_info_main>h4 {
        font-size: 25px;
    }

    .gym-detailed_info_main {
        width: 45%;
    }

    .features-box>p {
        font-size: 14px;
    }

    /* GYM DETAILED */
    .gym-detailed_map {
        width: 50%;
    }

    .gym-location_map {
        width: 100%;
    }

    .gym-detailed_info_main>h4 {
        font-size: 25px;
    }

    .gym-detailed_info_main {
        width: 45%;
    }

    .gym-dtl_location {
        width: 50%;
    }

    .gym-detailed_description {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .gym-detailed_description>h1 {
        font-size: 40px;
    }


    .gym-detailed_hero .gym-detailed_body {
        padding: 20% 6% 8%;
    }

    .feedback_link {
        font-size: 18px;
    }

    .gym-dtl_rating .rating_number,
    .gym_feedback__rayting>.rating_number {
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--primary-red);
        width: 45px;
        height: 40px;
        border-radius: 10px;
    }

    .gym-active_packages_container>h2,
    .team_container>h2 {
        font-size: 32px;
    }

    /* FEEDBACK */
    .rating__hero {
        padding: 20% 6% 9%;
    }

    .rating__hero>h1 {
        font-size: 40px;
    }

    .gym_feedback__hero>h2 {
        font-size: 25px;
        padding-bottom: 3%;
    }

    .team_wrapper {
        padding: 7% 0;
        gap: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

    .team_box {
        width: 60%;
        height: 220px;
    }

    .team_img {
        width: 140px;
        height: 140px;
    }

    .team_container>h2 {
        font-size: 32px;
        padding: 7% 0 7%;
    }

    .faq_question>h2 {
        font-size: 16px;
        font-weight: 500;
    }

    .faq_accordion {
        width: 80%;
        padding: 1.5% 2%;
    }

    /* ERROR PAGE */
    .pageNotFound_hero {
        padding: 14% 0 8%;
    }

    .pageNotFound_hero .animation {
        width: 400px;
        height: 400px;
    }

    .pageNotFound_body>.pageNotFound_text h1 {
        font-size: 70px;
    }

    .pageNotFound_text>.text_desc {
        font-size: 14px;
        padding: 2% 0 0 0;
    }
}


@media (max-width:768px) {

    .form-search_inner .search-input {
        font-size: 14px;
    }

    /* Header */

    .head-photo {
        display: none;
    }

    .head-text {
        width: 100%;
        padding-left: 6%;
        padding-bottom: 10%;
    }

    /* Partners */
    .slide>img {
        width: 80%;
    }

    .partner>img {
        width: 80px;
    }

    /* advantages */
    .advantages>h2 {
        padding-top: 3%;
        width: 100%;
    }

    .benefits {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3% 0 10%;
        gap: 30px;
    }

    .benefit {
        width: 309px;
        height: auto;
        border: 1px solid var(--primary-light-grey);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        transition: .5s all;
        cursor: pointer;
    }

    .benefit:hover {
        transform: scale(1.07);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }

    .benefit__title {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .benefit__title>i {
        font-size: 30px;
        padding: 10% 0 3%;
    }

    .benefit__title>h4 {
        padding: 0;
        font-size: 18px;
    }

    .adv-main {
        padding: 3% 6% 0;
    }

    .benefit>a {
        display: flex;
        flex: column;
        justify-content: center;
        padding: 2% 0;
    }

    .adv-text {
        /* padding: 2% 3%; */
        width: 100%;
    }

    .adv-text p {
        font-size: 14px;
    }

    /* Gyms */

    .gym-heading>h2 {
        font-size: 30px;
    }

    .gym-heading>button {
        font-size: 14px;
        padding: 1% 2.5%;
    }

    .gym-wrapper {
        padding: 5% 0;
        gap: 12px;
    }

    .gym-container {
        width: 350px;
        height: 360px;
    }

    /* Why us */
    .why-us_body {
        display: flex;
        flex-direction: column;
        gap: 40px;
        width: 100% !important;
    }

    .why-us>h2 {
        font-size: 30px;
    }

    .why-us_text>h4 {
        font-size: 18px;
    }

    .why-us_text {
        width: 100%;
    }

    .why-us_description {
        width: 90% !important;
    }

    .why-us_description>p {
        padding: 3% 0;
    }

    .why-us_img>img {
        width: 100%;
    }

    .why-us_img {
        padding-bottom: 10%;
        position: relative;
        width: 90%;
    }

    .why-us_img .rectangle {
        position: absolute;
        left: 27%;
        top: -4.7%;
        width: 50%;
        height: 100%;
        background: linear-gradient(90deg, rgba(26, 41, 128, 0.45) 0%, rgba(38, 208, 206, 0.45) 100%);
    }

    /* Testimonials */

    .testimonials-header>h2 {
        font-size: 30px;
    }

    .testimonials-header {
        padding: 10% 0 5%;
    }

    /* Price table */
    .price-table h2 {
        font-size: 30px;
    }

    .packages {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 30px;
    }

    .package {
        width: 360px;
        height: 400px;
        margin: auto;
    }

    /* Testimonials */

    .promotion {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
    }

    .promotion-main h2 {
        font-size: 30px;
        padding: 5% 0 2%;
    }

    .promotion-main p {
        font-size: 16px;
        width: 100%;
    }

    .promotion-video {
        width: 100%;
        height: 400px;
    }

    /* Footer */
    .footer-body {
        display: flex;
    }

    .sports,
    .sp-logo,
    .companies,
    .contact {
        width: 50%;
    }

    .companies,
    .contact {
        padding-top: 9%;
    }

    .contact>.icons>i {
        padding: 4%;
        font-size: 24px;
        cursor: pointer;
        transition: .4s all;
    }

    .footer-content p {
        text-align: center;
        font-size: 14px;
        padding: 2% 0;
    }

    .footer-title {
        font-size: 18px;
        padding: 3% 0;
    }

    .sport>a,
    .company>a,
    .contact>a {
        font-size: 16px;
    }

    .sp-logo>p {
        font-size: 16px;
    }


    /* About Section */

    .about-text>h1 {
        font-size: 28px;
    }

    .about-text .txt-description {
        font-size: 14px;
        width: 100%;
    }

    .partner_advantages h2,
    .user_advantages h2 {
        font-size: 30px;
    }

    .partner_main>h4,
    .user_main>h4 {
        font-size: 30px;
        padding-bottom: 4%;
        text-align: center;
    }

    .partner_body,
    .user_body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;
    }

    .partner_description>p,
    .user_description>p {
        width: 100%;
        padding-top: 2%;
    }

    .partner_advantages h2,
    .user_advantages h2 {
        padding: 10% 0;
    }

    .partner_main,
    .user_main {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
    }

    .img-container {
        width: 100%;
        height: 274px;
    }

    .partner_img,
    .user_img {
        width: 394px;
        height: 350px;
    }

    .about_section_partners>h2 {
        font-size: 26px;
    }


    .counter_container {
        display: flex;
        flex-direction: column;
        gap: 45px;
        align-items: center;
    }

    .counter_box {
        width: 260px;
        height: 170px;
    }

    /* GYM PAGE */

    .gyms_section_promotion>h2 {
        font-size: 22px;
    }

    .gym-heade>h2 {
        font-size: 25px;
    }

    .gym_header>h1 {
        font-size: 26px;
        padding: 25% 6% 0%;
    }

    .select_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 25px;
    }

    .select-group {
        width: 100%;
    }

    .select_wrapper>.submit-btn {
        height: 48px;
        width: 50%;
    }

    /* PRICE PAGE */
    .price-hero__body {
        padding: 25% 6% 10%;
    }

    .price-hero__body>h1 {
        font-size: 35px;
    }

    .price-box_title>h3 {
        font-size: 20px;
        padding: 2% 2%;
    }

    .price-box_title>p {
        font-size: 15px;
    }

    .price-box_wrapper {
        padding: 2% 0 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 150px;
    }

    .price-box_body>.price_front {
        width: 60%;

    }

    .price-box_body>.price_back {
        width: 60%;



    }

    .price_btn {
        padding: 8%;
        text-align: center;
    }

    .price-box_wrapper .price-box_body .name {
        font-size: 28px;
        padding-top: 2%;
    }

    .price-box_wrapper .price-box_body .info {
        font-size: 18px;
    }

    .back_list li span {
        font-size: 16px;
    }

    .price_number>p {
        font-size: 45px;
    }

    .price_number>i {
        font-size: 30px;
    }

    .price_btn>a {
        padding: 7px 30px;
        font-size: 18px;
    }

    /* TABLE */

    .table_head {
        font-size: 14px;

    }

    .table_row .fa-xmark,
    .table_row .fa-check {
        font-size: 16px;
    }

    .table_row .feature_title button {
        font-size: 14px;
        text-align: center
    }




    .feature_title {
        font-size: 14px;
    }

    .faq-client_box>h4 {
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 1px;
        padding-bottom: 2%;
        color: var(--primary-dark-blue);
    }

    .faq-client_box>p {
        font-size: 14px;
        width: 90%;
        line-height: 32px;
    }

    .faq_boxes_top,
    .faq_boxes_bottom {
        display: flex;
        flex-direction: column;
        gap: 25px;
    }

    .faq-client_box {
        width: 100%;
    }

    /* contact Page */

    .contact_head {
        padding: 25% 6% 8%;
    }

    .contact_head>h2 {
        font-size: 28px;
    }

    .contact__container .contact_info {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
    }

    .contact__container .contact_info .contact_flex>i {
        font-size: 35px;
        padding-bottom: 5%;
    }

    .contact__container .contact_info .contact_flex {
        width: 60%;
        padding: 4% 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid var(--primary-light-grey);
        cursor: pointer;
        border-radius: 10px;
        transition: .5s all;

    }

    .contact__container .contact_info .contact_flex:hover {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
        transform: scale(1.1);
    }

    .contact__form_hero form {
        width: 80%;
    }

    .contact_img {
        width: 80%;
    }

    .contact__form_hero {
        padding: 8% 0 4%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        gap: 35px;
    }

    /* USER INTERFACE DETALIED */
    .user-interface_hero,
    .control-panel_hero,
    .operator-panel_hero,
    .faq_hero {
        width: 100%;
        padding: 18% 6% 8%;
        background: var(--primary-light-grey);
    }

    .registration-stage,
    .qr-stage {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .registration-stage>.__description,
    .qr-stage>.__description {
        width: 80%;
    }

    .registration-stage>.__description {
        padding-bottom: 2%;
    }

    .user-interface_stages {
        width: 100%;
        padding: 8% 6%;
    }

    .user-interface_body>h1,
    .control-panel_body>h1,
    .operator-panel_body>h1 {
        font-size: 35px;
    }

    .user-interface_body>p,
    .control-panel_body>p {
        font-size: 14px;
        width: 90%;

    }

    .registration-stage>.__image {
        width: 80%;
        height: 450px;
        position: relative;
    }

    .registration-stage>.__image>img {
        width: 80%;
        height: auto;
        position: absolute;
        top: 8%;
        left: 9%;
    }

    .registration-stage>.__description>p,

    .package-stage>.__description>p,
    .qr-stage>.__description>p {
        font-size: 28px;
    }

    .search-stage {
        padding: 8% 0 2% 0;
        display: flex;
    }

    .search-stage>.__image {
        width: 60%;
        height: auto;
    }

    .search-stage>.__description>p {
        font-size: 25px;
    }

    .information-stage>.__description>p {
        font-size: 28px;
    }

    .information-stage>.__description>span {
        font-size: 16px;
    }

    .search-stage>.__description>span {
        font-size: 16px;
        width: 80%;
        line-height: 25px;
    }

    .package-stage {
        padding-bottom: 3%;
    }

    .package-stage>.__description>span,
    .qr-stage>.__description>span {
        line-height: 25px;
        font-size: 16px;
    }

    .qr-stage>.__image {
        width: 90%;
        height: auto;
    }

    /* SOFTWARE PANEL */
    .functionality-section,
    .class-section,
    .customer-section,
    .finance-section,
    .campaign-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10% 6%;
    }

    .functionality-section>.__description {
        width: 100%;
        padding-bottom: 8%;
    }

    .functionality-section>.__description>h3,
    .campaign-section>.__description>h3,
    .finance-section>.__description>h3 {
        font-size: 28px;
        width: 100%;
    }

    .functionality-section>.__image {
        width: 100%;
        height: 480px;
        position: relative;
    }

    .functionality-section>.__image>img {
        position: absolute;
        top: -7%;
    }

    .class-section>.__image {
        width: 100%;
        height: auto;
    }

    .class-section>.__description {
        width: 100%;
        padding-top: 5%;
    }

    .customer-section>.__description {
        width: 100%;
        padding-bottom: 8%;
    }

    .customer-section>.__image {
        width: 100%;
        height: auto;
    }

    .finance-section>.__description,
    .campaign-section>.__description {
        width: 100%;
        padding-bottom: 5%;
    }

    .finance-section>.__image,
    .campaign-section>.__image {
        width: 100%;
        height: auto;
    }

    .user-panel_advantages,
    .operator-web_advantages {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .user-panel_advantages>.user-panel_advantage {
        width: 80%;
        height: 40%;
    }

    .user-panel_advantages>.user-panel_advantage>i,
    .operator-web_advantages>.operator-panel_advantage>i {
        padding: 7% 1% 3% 7%;
    }

    .user-panel_advantages>.user-panel_advantage>h4,
    .operator-web_advantages>.operator-panel_advantage>h4 {
        padding: 1% 1% 3% 7%;
    }

    .operator-web_advantages>.operator-panel_advantage {
        width: 80%;
        height: 40%;
    }

    .more-detailed>h2 {
        font-size: 18px;
    }

    .more-detailed>button {
        background: var(--primary-white);
        color: var(--primary-red);
        font-weight: 700;
        font-size: 16px;
        letter-spacing: .5px;
        border-radius: 10px;
        padding: 10px 24px;
        border: 1px solid transparent;
        transition: .3s all;
    }

    /* OPERATOR PANEL */
    .operator-web-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10% 6% 0;
    }

    .operator-web-section>.__description {
        width: 90%;
    }

    .operator-web-section>.__description>h3 {
        font-size: 30px;
        padding-bottom: 0;
    }

    .class-section>.__description>p {
        padding-bottom: 5%;
    }

    .operator-web-section>.__image,
    .operator-mobil-section>.__image {
        width: 100%;
        height: auto;
    }

    .operator-mobil-section {
        display: flex;
        flex-direction: column;
    }

    .operator-mobil-section>.__description {
        width: 100%;
        padding-top: 6%;
    }

    /* TERMS */
    .terms-hero_body {
        padding: 20% 6% 8%;
    }

    .terms-hero_body>h1 {
        font-size: 30px;
    }

    /* LOGIN PAGE */
    .login_card_body {
        width: 80%;
    }

    .login_container {
        width: 100%;
        padding: 30% 6%;
        display: flex;
        justify-content: center;
    }


    /* FILTER PACKAGES */

    .filter_packs {
        margin-bottom: 7%;
    }


    .filter_pack {
        padding: 4px 11px;
        border-radius: 40px;
        font-size: 14px;
    }

    .active-packages_wrapper {
        display: flex !important;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        /* gap: 20px; */
    }

    .gym-active-package {
        width: 340px;
        height: 330px;
        gap: 25px;
    }

    /* .package_price {
        width: 60%;
        height: 60px;
    } */

    .package_price>.active_packages__name,
    .package_price>.active_packages__price {
        font-size: 16px;
    }

    .package_price__list--item>span {
        font-weight: 600;
        font-size: 16px;
        letter-spacing: .2px;
    }

    .package_price__list--item>p {
        font-size: 16px;
        padding-left: 2%;
    }

    .gym-active-package>.package_price--button>button {
        padding: 3% 7%;
        font-size: 16px;
    }

    /* GYM DETAILED */
    .gym-detailed_info_wrapper {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .gym-detailed_map {
        width: 100%;
    }

    .gym-location_map {
        width: 100%;
    }

    .gym-detailed_description>h1 {
        font-size: 30px;
    }

    .gym-detailed_info_main>h4 {
        font-size: 22px;
    }

    .gym-detailed_info_main {
        width: 100%;
    }

    .features-box>a {
        font-size: 14px;
    }

    /* FEEDBACK */
    .rating__hero {
        padding: 24% 6% 10%;
    }

    .rating__hero>h1 {
        font-size: 35px;
    }

    .gym_feedback__hero>h2 {
        font-size: 20px;
        padding-bottom: 3%;
    }

    .gym-dtl_location {
        width: 70%;
        color: var(--primary-grey-hover);
    }

    .gym_feedback__main>p {
        font-size: 16px;
    }

    .gym_feedback__main>span {
        font-size: 14px;
    }

    .like_number>span {
        font-size: 14px;
    }

    .star-container>svg {
        width: 18px !important;
        height: 18px !important;
    }

    .gym_feedback_rating>.like_number>i {
        font-size: 18px;
    }

    .gym_feedback__wrapper>a {
        font-size: 16px;
    }

    .team_wrapper {
        padding: 7% 0 0;
        gap: 120px;
    }

    .team_box {
        width: 60%;
        height: 200px;
    }

    .team_img {
        width: 140px;
        height: 140px;
    }

    .team_container>h2 {
        font-size: 30px;
        padding: 6% 0 9%;
    }

    .faq_body>h1 {
        font-size: 30px;
    }

    .faq_hero {
        width: 100%;
        padding: 22% 6% 10%;
        background: var(--primary-light-grey);
    }

    .faq_accordion {
        width: 80%;
        padding: 1.8% 2.3%;
    }

    .faq_content>p {
        font-size: 15px;
    }

    /* ERROR PAGE */
    .pageNotFound_hero {
        padding: 16% 0 8%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .pageNotFound_text {
        padding: 0;
    }
}

@media (max-width:576px) {
    .logo {
        width: 90px;
    }

    .nav-logo {
        display: block;
        margin-bottom: 5px;
        position: absolute;
        left: 17%;
        bottom: 7px;
    }

    .partner>img {
        width: 70px;
    }

    /* Header */
    .advantages>h2 {
        width: 100%;
        font-size: 22px;
    }

    .gym-heading>h2 {
        font-size: 22px;
        padding: 5% 0;
    }

    .why-us>h2 {
        font-size: 24px;
        padding: 5% 0;
    }

    .why-us_text>h4 {
        font-size: 16px;
    }

    .testimonials-header>h2 {
        font-size: 24px;
        padding-bottom: 5%;
    }

    .price-table h2 {
        font-size: 24px;
        padding: 6% 0;
    }

    .promotion-main h2 {
        font-size: 24px;
        padding: 7% 0 2%;
    }

    .promotion-main p {
        font-size: 14px;
    }

    .sp-logo {
        width: 100%;
    }

    .sp-logo>p {
        font-size: 14px;
        color: var(--clr-grey);
        padding: 7% 0 0;
    }

    .footer-body {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .footer-content {
        padding: 5% 0;
    }

    .footer-content p {
        padding: 2% 0;
        font-size: 12px;
    }

    .footer-content p>span {
        font-size: 12px;
    }

    /* .mobile-menu-icon {
        padding: 1%;
        display: flex;
        position: absolute;
        top: 15px;
        right: 25px;
        font-size: 30px;
    } */

    /* gym */
    .select-btn {
        padding: 3%;
        background: var(--primary-white);
    }

    .partner_advantages h2,
    .user_advantages h2 {
        font-size: 24px;
    }

    .partner_main>h4,
    .user_main>h4 {
        font-size: 24px;
        padding-bottom: 4%;
        text-align: center;
    }

    .about_section_partners>h2 {
        font-size: 22px;
    }

    .price-hero__body>h1 {
        font-size: 24px;
    }

    .price-box_title>h3 {
        font-size: 16px;
        padding: 2% 2%;
    }

    /* .price-box_wrapper .price-box_body {
        width: 80%;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    } */

    .price_number>p {
        font-size: 32px;
    }

    .price_number>i {
        font-size: 22px;
        color: var(--primary-white);
    }

    /* TABLE */

    .table_head {
        font-size: 12px;
        padding: 5px;

    }

    .table_row .fa-xmark,
    .table_row .fa-check {
        font-size: 14px;
    }

    .table_row .feature_title button {
        font-size: 12px;
        max-width: 70%;
        text-align: center;
    }

    .table_row td {
        max-width: 60%;
    }

    /* USER INTERFACE DETALIED */
    .user-interface_hero,
    .control-panel_hero,
    .operator-panel_hero,
    .faq_hero {
        padding: 24% 6% 10%;
    }

    .user-interface_body h1,
    .control-panel_body h1,
    .operator-panel_body h1 {
        font-size: 28px !important;
    }

    .registration-stage,
    .qr-stage {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .registration-stage>.__description,
    .qr-stage>.__description {
        width: 80%;
    }

    .registration-stage>.__description {
        padding-bottom: 2%;
    }

    .user-interface_stages {
        width: 100%;
        padding: 8% 6%;
    }

    .user-interface_body>h1,
    .control-panel_body>h1,
    .operator-panel_body>h1,
    .faq_body>h1 {
        font-size: 35px;
    }

    .registration-stage>.__image {
        width: 80%;
        height: 310px;
        position: relative;
    }

    .registration-stage>.__image>img {
        width: 80%;
        height: auto;
        position: absolute;
        top: 8%;
        left: 10%;
    }

    .registration-stage>.__description>p,
    .package-stage>.__description>p,
    .qr-stage>.__description>p {
        font-size: 22px;
        width: 100%;
    }

    .search-stage {
        padding: 8% 0 2% 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .search-stage>.__image {
        width: 90%;
        height: auto;
    }

    .search-stage>.__description {
        width: 80%;
    }

    .search-stage>.__description>p {
        font-size: 22px;
    }

    .information-stage>.__description {
        padding-top: 10%;
    }

    .information-stage>.__description>p {
        font-size: 22px;
        width: 100%;
    }

    .search-stage>.__description>span {
        font-size: 16px;
        width: 80%;
        line-height: 25px;
    }

    .package-stage {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .package-stage>.__image {
        width: 80%;

    }

    .package-stage>.__description {
        width: 80%;
        padding-bottom: 14%;
    }

    /* SOFTWARE PANEL */
    .functionality-section>.__image {
        width: 100%;
        height: 380px;
        position: relative;
    }

    .functionality-section>.__image>img {
        position: absolute;
        top: -5%;
    }

    .operator-web-section>.__description>p,
    .operator-mobil-section>.__description>p,
    .class-section>.__description>p,
    .finance-section>.__description>p,
    .campaign-section>.__description>p,
    .customer-section>.__description>p,
    .functionality-section>.__description>p {
        font-size: 14px;
    }

    .operator-web-section>.__description>h3,
    .operator-web-section>.__description>h3 {
        font-size: 28px;
        padding-bottom: 5%;
    }

    .user-panel_advantages>.user-panel_advantage>p,
    .operator-web_advantages>.operator-panel_advantage>p {
        font-size: 14px;
    }

    .operator-panel_body>p {
        font-size: 14px;
    }

    /* Terms */
    .terms-hero_body {
        padding: 24% 6% 10%;
    }

    .terms-hero_body>h1 {
        font-size: 28px;
    }

    /* LOGIN PAGE */
    .login_card_body {
        width: 90%;
    }

    .login_container {
        padding: 30% 6%;
    }

    .phone_code-disabled {
        font-size: 14px;
    }

    .form_phone>.form_input {
        padding: 3% 5%;
    }

    .login__btn {
        font-size: 14px;
    }

    .login_card_body>h5 {
        font-size: 12px;
    }

    .form-search_inner .search-input {
        font-size: 12px;
    }

    /* SEARCH */


    /* FILTER PACKAGES */

    .filter_packs {
        margin-bottom: 7%;
        display: flex;
        flex-wrap: wrap;
    }


    .filter_pack {
        padding: 5px 12px;
        font-size: 12px;
    }

    .active-packages_wrapper {
        display: flex !important;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        /* gap: 20px; */
    }

    .gym-active-package {
        width: 290px;
        height: 300px;
        gap: 25px;
    }

    /* 
    .package_price {
        width: 40%;
        height: 50px;
    } */

    .package_price>.active_packages__name,
    .package_price>.active_packages__price,
    .active_packages__original_price,
    .active_packages__discounted_price {
        font-size: 14px;
    }

    .package_price__list--item>span {
        font-weight: 600;
        font-size: 14px;
        letter-spacing: .2px;
    }

    .package_price__list--item>p {
        font-size: 14px;
        padding-left: 2%;
    }

    .gym-active-package>.package_price--button>button {
        padding: 3% 7%;
        font-size: 14px;
    }

    .f-select>select {
        height: 40px;
    }

    /* GYM DETAILED */
    .detailed-social_media {
        width: 45px;
        height: 45px;
    }

    .detailed-social_media>a>i {
        font-size: 24px;
    }

    .detailed-social_media>a>.fa-instagram:hover {
        width: 28px;
        height: 28px;
    }

    .gym-detailed_hero .gym-detailed_body {
        padding: 27% 6% 10%;
    }

    .gym-dtl_location {
        width: 70%;
    }

    .gym-detailed_description>h1 {
        font-size: 25px;
    }

    .gym-dtl_rating .rating_number,
    .gym_feedback__rayting>.rating_number {
        width: 40px;
        height: 30px;
    }

    .gym-dtl_rating .rating_number>span,
    .gym_feedback__rayting>.rating_number>span {
        font-size: 18px;
    }

    .lg-react-element {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: repeat(5, 14vw);
        grid-gap: 10px;
        padding: 3% 0;
    }

    .promotion-video {
        width: 100%;
        height: auto;
    }

    /* TEAM */
    .team_wrapper {
        display: flex;
        flex-direction: column;
        gap: 120px;
        padding: 7% 0;
    }

    .team_box {
        margin-bottom: 0;
        width: 80%;
    }

    .team_img {
        width: 130px;
        height: 130px;
    }

    .team_container>h2 {
        margin-bottom: 17px;
        font-size: 28px;
        padding: 6% 0 14%;
    }

    .team_description {
        padding: 20% 2% 2% 2%;
    }

    .faq_body>h1 {
        font-size: 25px;
    }

    .faq_hero {
        width: 100%;
        padding: 28% 6% 12%;
        background: var(--primary-light-grey);
    }

    .faq_accordion {
        width: 90%;
        padding: 2.5% 2.7%;
    }
}

@media (min-width: 320px) and (max-width: 480px) {

    /* .nav-link-right_mob {
       
        margin-bottom: 0; 
    } */
    .nav-logo {
        display: block;
        margin-bottom: 5px;
        position: absolute;
        left: 17%;
        bottom: 7px;
    }

    .nav-link-right_mob {
        display: block;
        margin-bottom: 30px;
    }

    .main-menu_item {
        font-size: 20px;

    }

    /* Home page */
    .advantages>h2 {
        font-size: 20px;
    }

    .why-us>h2 {
        font-size: 20px;
    }

    .head-text>h1 {
        font-size: 28px;
        line-height: 55px;
    }

    .head-text>p {
        font-size: 20px;
    }

    .partner>img {
        width: 50px;
    }

    /*PROMOTION */
    .promotion-main>h2 {
        font-size: 18px;
    }

    .promotion-main>p {
        font-size: 14px;
    }

    /* ABOUT */
    .partner_advantages h2,
    .user_advantages h2 {
        font-size: 22px;
    }

    .partner_main>h4,
    .user_main>h4 {
        font-size: 22px;
        padding-bottom: 4%;
        text-align: center;
    }

    .about_section_partners>h2 {
        font-size: 20px;
    }

    .price-hero__body>h1 {
        font-size: 20px;
    }

    .price-box_title>h3 {
        font-size: 14px;
        padding: 2% 2%;
    }

    .price_table .table_title>h4 {
        font-size: 20px;
    }

    .price_table .table_title>p {
        font-size: 14px;
    }

    .table_head {
        font-size: 12px;

    }

    .price_table {
        padding: 0;
    }

    table {
        width: 70%;
    }

    .table_row .fa-xmark,
    .table_row .fa-check {
        font-size: 13px;
    }

    .table_row .feature_title button {
        font-size: 12px;
        max-width: 50%;
        text-align: center;
    }

    .table_row td {
        max-width: 60%;
    }

    .price-box_body>.price_front {
        width: 60%;

    }

    .price-box_body>.price_back {
        width: 60%;

    }

    .price-box_wrapper {
        padding: 2% 0 40%;
    }

    .price-box_wrapper .price-box_body .name {
        font-size: 20px;
        padding-top: 2%;
    }

    .price_back>h2 {
        font-size: 20px;
    }

    .back_list>li>i {
        font-size: 14px;
    }

    .back_list>li>span {
        font-size: 14px;
    }

    .price_btn {
        padding: 10% 0 8%;
        text-align: center;
    }


    /* FEEDBACK */
    .rating__hero {
        padding: 30% 6% 10%;
    }

    .rating__hero>h1 {
        font-size: 28px;
    }

    .gym_feedback__hero>h2 {
        font-size: 16px;
        padding-bottom: 3%;
    }

    .gym-dtl_location {
        font-size: 14px;
        width: 70%;
        color: var(--primary-grey-hover);
    }

    .gym_feedback__main {
        width: 60%;
    }

    .gym_feedback__main>p {
        font-size: 14px;
    }

    .gym_feedback__main>span {
        font-size: 14px;

    }

    .like_number>span {
        font-size: 12px;
    }

    .star-container>svg {
        width: 12px !important;
        height: 12px !important;
    }

    .gym_feedback_rating>.like_number>i {
        font-size: 14px;
    }

    .gym_feedback__wrapper>a {
        font-size: 14px;
    }

    .gym_feedback__container {
        width: 100%;
        padding: 8% 6%;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .promotion-video {
        width: 100%;
        height: auto;
    }

    .team_description {
        padding: 22% 2% 2% 2%;
    }

    .faq_body>h1 {
        font-size: 22px;
    }

    .faq_hero {
        width: 100%;
        padding: 28% 6% 12%;
        background: var(--primary-light-grey);
    }

    .faq_accordion {
        width: 90%;
        padding: 3% 3.2%;
    }

    .faq_question>h2 {
        font-size: 14px;
        font-weight: 500;
    }

    .faq_content>p {
        font-size: 14px;
    }

    .gym-heading>.link_btn {
        padding: 1.5% 2.7%;
        font-size: 14px;
    }

    .user-interface_body h1 {
        font-size: 22px !important;
    }

    .user-interface_body>p,
    .control-panel_body>p,
    .operator-panel_body>p {
        font-size: 12px;
        width: 90%;
    }

    .package-stage>.__description,
    .qr-stage>.__description,
    .registration-stage>.__description,
    .information-stage>.__description,
    .class-section>.__description {
        width: 90%;
    }

    .package-stage>.__description>p,
    .qr-stage>.__description>p,
    .registration-stage>.__description>p,
    .information-stage>.__description>p,
    .search-stage>.__description>p {
        font-size: 18px;
        width: 90%;
    }

    .search-stage>.__description>span,
    .package-stage>.__description>span,
    .qr-stage>.__description>span,
    .registration-stage>.__description>span,
    .information-stage>.__description>span,
    .class-section>.__description>p {
        font-size: 14px;
        width: 100%;
    }

    .class-section>.__description>h3,
    .customer-section>.__description>h3,
    .operator-web-section>.__description>h3,
    .operator-web-section>.__description>h3,
    .operator-mobil-section>.__description>h3 {
        font-size: 22px;
    }

    .about-text>h1 {
        font-size: 22px;
    }

    .user-panel_advantages>.user-panel_advantage>h4,
    .operator-web_advantages>.operator-panel_advantage>h4 {
        padding: 1% 1% 3% 7%;
        font-size: 16px;
    }

    .user-interface_body h1,
    .control-panel_body h1,
    .operator-panel_body h1 {
        font-size: 22px !important;
    }

    .functionality-section>.__description>h3,
    .campaign-section>.__description>h3,
    .finance-section>.__description>h3 {
        font-size: 20px;
        width: 100%;
    }

    .functionality-section>.__description,
    .class-section>.__description,
    .finance-section>.__description,
    .campaign-section>.__description,
    .customer-section>.__description {
        width: 90%;
    }

    .functionality-section>.__description>p,
    .class-section>.__description>p,
    .finance-section>.__description>p,
    .campaign-section>.__description>p,
    .customer-section>.__description>p {
        width: 100%;
        line-height: 26px;
    }

    .more-detailed>h2 {
        font-size: 14px;
    }

    .more-detailed>button {
        font-size: 14px;
        padding: 5px 12px;
    }

    /* ERROR PAGE */
    .pageNotFound_hero .animation  {
        width: 300px;
        height: 300px;
    }
    .pageNotFound_hero {
        padding: 24% 0 14%;
    }
    .pageNotFound_body>.pageNotFound_text h1 {
        font-size: 50px;
    }
    .pageNotFound_body>button>a {
        font-size: 14px;
    }
}

@media only screen and (min-device-width: 390px) and (max-device-width: 844px) and (-webkit-min-device-pixel-ratio: 3) {

    /* CSS rules for iPhone 14 */
    .nav-link-right_mob {

        margin-bottom: 0;
    }

}